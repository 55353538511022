$yellow-color:#FFC601;
$black-color:#000000;
$white-color: #ffffff;
.container{
    @media screen and (max-width: 767px){

        padding: 0 20px;
    }
}

.footer-div{
    background-color: $black-color;
    color: $white-color;
    padding: 50px 0 0 0;
    border-top: 1px solid $white-color;
    .footer-heads{
        font-size: 20px;
        margin-bottom: 25px;
        color: $yellow-color;
        @media screen and (max-width: 767px){
            margin-bottom: 10px;
            margin-top: 20px;
        }
    }
    .logo_div{
        img{
            margin-bottom: 30px;
        }
    }
    .footer-logo{
        width: 150px;
    }
    iframe{
        width: 300px;
        height: 300px;
        display: block;
        margin-top: 30px;
    }
    .nav_2{
        ul{
            list-style-type: none;
            padding-left: 0px;
            li{
                padding-left: 0px;
                margin-bottom: 7px;
                cursor: pointer;
                a{
                    color: $white-color;
                    &:hover{
                        color: #faf0cf;
                    }
                }
            }
        }
     
      
    }
    .address-div{
        padding-left: 0px;
        i{
            padding-right: 10px;
            font-size: 15px;
        }
        li{
            display: flex;
            margin-bottom: 7px;
            &.end{
               
                align-items: center;
                i{
                    margin-top: 4px;
                }
            }
            &.start{
                
                align-items: flex-start;
                i{
                    margin-top: 7px;
                }
            }
            a{
                color: $white-color;
                text-decoration: none;
            }
           
        }
    }
        // text-align: right;
    .social_div{
        display: inline-block;
        list-style-type: none;
        padding-left: 0px;
        li{
            display: inline;
            padding-right: 10px;
            margin-right: 7px;
            a{
                color: $white-color;
            }
        }
        
    }
    .social_div_1{
        margin-top: 0px;
        @media screen and (max-width: 767px) {
            margin-top: 0px;
        }
    }
    .form_container{
        margin-top: 30px;
        @media screen and (max-width: 767px) {
            margin-top: 0px;
        }
        input{
            width: 100%;
            padding: 5px 10px;
            border-radius: 30px;
        }
        .footer-heads{
            margin-bottom: 10px;
            font-size: 17px;
            @media screen and (max-width: 767px) {
                margin-bottom: 10px;
                margin-top: 30px;
            }
        }
    }
    .copyright_div{
        border-top: 1px solid $white-color;
        padding: 10px 0;
        margin-top: 20px;
        a{
            color: $white-color;
            text-decoration: underline !important;
        }
    }
    .terms_div{
        text-align: right;
        ul{
            list-style-type: none;
            padding-left: 0px;
            li{
                display: inline-block;
                padding-right: 10px;
                border-right: 1px solid $white-color;
                padding-left: 10px;
                &:last-child{
                    border-right: none;
                }
            }
        }
    }
    .yellow-button{
        @media screen and (max-width: 1400px) and (min-width: 1201px) {
            font-size: 16px;
            padding: 5px 25px;
        }
    }
}
.yellow-button{
    background-color: $yellow-color;
    color: $black-color;
    border-radius: 30px;
    font-size: 17px;
    padding: 5px 30px;
    box-shadow: none;
    border: $black-color;
    margin-top: 5px;
    @media screen and (max-width: 1200px) {
        //do Smth
        width: fit-content;
    }
    &:hover{
        background-color: $white-color;
        color: $black-color;
    }

}
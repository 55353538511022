/*Admin CSS*/
body {
	background-color: #f3f4f8;
}

.admin-login-img {
	height: 32em
}

.dashboard-heading {
	font-weight: 900;
}

.admin-card {
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	border-radius: 12px;
	font-size: 0.75em;
	background-color: #fff;
}

.admin-card-section {
	background-color: #f8f9fa;
	border-radius: 12px;
}

.admin-card-header {
	font-weight: 700;
	font-size: 1.5em;
}

.admin-card-button {
	padding: 0.5%;
	color: #fff;
	background: #ff8b00;
	border: none;
	border-radius: 200px;
	width: 12em;
	font-size: 1em;
	font-weight: 700;
	transition: all 0.1s ease-in
}

.admin-card-button:hover {
	background-color: #e67d00;
}

.admin-card-time {
	font-size: 0.9em;
	color: #999;
	line-height: 0.1;
}

.admin-card-date {
	font-size: 0.9em;
	color: #222;
	font-weight: bold;
}

.heading-placeholder {
	content: "";
	background-color: #cdcdcd;
	border-radius: 100px;
	height: 10px;
}

.para-placeholder {
	content: "";
	background-color: #ededed;
	border-radius: 100px;
	height: 10px;
}

label {
	font-size: 0.8em;
	font-weight: bold
}

.inputs-card {
	max-height: 350px;
	overflow-y: scroll;
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 50px;
}



.blog-preview-header {
	background-color: #99a5ac;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	height: 30px;
}

.blog-title-main {
	font-weight: 800;
	font-size: 1.75m;
	margin-top: 15px;
}

.blog-img-main {
	height: 16em;
	width: auto;
	border-radius: 8px;
}

.blog-author-img {
	height: 50px;
	width: 50px;
	border-radius: 200px;
}


.blog-img-horizontal {
	height: auto;
	width: auto !important;
	border-radius: 8px;
}

.blog-link {
	text-decoration: none;
	color: cornflowerblue;
	font-size: 1.1em;
}


.blog-content p {
	font-size: 1.4em;
	overflow-wrap: break-word;
}

.blog-content li {
	font-size: 1.4em;
}

.content-button {
	height: 180px;
	width: 180px;
	cursor: pointer;
}

.content-button-2 {
	height: 150px;
	width: 150px;
	cursor: pointer;
}

.content-button:hover {
	border: 1.45px solid #888;
}

.blog-button-2 {
	font-size: 0.8em;
	border-radius: 200px;
	width: 5em;
}

.content-button-2:hover {
	border: 1.45px solid #888;
}

.header-four {
	font-size: 2em;
}

[title^="H2"] {
	font-size: 2.5em;
	font-weight: bold;
}

[title^="H3"] {
	font-size: 2em;
	font-weight: bold;
}

[title^="H4"] {
	font-size: 1.5em;
	font-weight: bold;
}


.case-study-logo {
	width: 8em;
}

/* .case-study-description{
		white-space: pre-wrap;
		border: none;
		padding: 0.5rem;
		color: black;
		border-radius: 4px;
		resize: none;
		box-sizing: border-box;
		flex: 1 1 auto;
		overflow-wrap: break-word;
} */


.case-study-description {
	white-space: normal;
	border: none;
	padding: 0.5rem;
	color: white;
	border-radius: 4px;
	resize: none;
	box-sizing: border-box;
	flex: 1 1 auto;
	overflow-wrap: break-word;
	display: -webkit-box;
	/* Multiline ellipsis */
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	font-size: 1.3em;
	padding: 3px;
}

.case-study-description-page {
	white-space: normal;
	border: none;
	padding: 0.5rem;
	color: white;
	border-radius: 4px;
	resize: none;
	box-sizing: border-box;
	flex: 1 1 auto;
	overflow-wrap: break-word;
	display: -webkit-box;
	/* Multiline ellipsis */
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	font-size: 1em;
	padding: 3px;
}


.case-card-container {
	position: relative;
	display: inline-block;
	height: 20em;
	width: 16em;
	border-radius: 5px;
}

/* .casestudie-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; 
} */

.case-child-card {
	margin-bottom: 20px;
}

.case-child-card:last-child {
	margin-bottom: 0;
}

.card-icon {
	width: auto;
	height: 3em;
	border-radius: 0 0 0 8px;
	display: flex;
	justify-content: end;

}

.card-stack {
	z-index: 50;
	border-radius: 10px;

}

.card-stack li {
	padding: 5px;
	font-size: 0.8em;
	border-radius: 3px;
}

.case-color-picker {
	position: absolute;
	z-index: 100;
	/* top: 0em;
	left: 6em; */
}
.case-study-description-container {
    height: 80%;
    max-height: 80%;
    overflow: hidden;
    position: relative;
    align-self: start;
    display: block;
}
.case-study-custom-icon {
    width: auto;
    height: 4em;
    border-radius: 0 0 0 8px;
}
.previewPattern {
    position: absolute;
    top: 0;
}

.card-custom-v2 {
    display: inline-block;
    height: 20em;
    width: 16em;
    position: relative;
}
.check-circle {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    color: green;
}



/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) {
	.admin-login-img {
		height: 12em
	}
}





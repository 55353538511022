#casestudy-content{
    position: relative;
    height:10em;
    overflow: hidden;
    filter: blur(5px); 
    transition: all 0.5s ease; 
  }
  
#casestudy-content.expanded {
height: auto; 
filter: none; 
}

#visible-container p {
  margin-bottom: 0;
}
.white-bg {
    background-color: white;
}

.black-color {
    color: black;
}

.grey-blue-bg {
    background-color: #dee6e6;
}

.med-font {
    font-size: 0.9rem;
}

.color-grey {
    color: grey;
}

.tip {
    padding: 6px;
    font-size: 0.9rem;
    border-radius: 15px;
    background-color: #dee6e6;
}


.table-container {
    height: 80vh;
    width: 100%;
}

.expert-card .card-content {
    border-radius: 11px;
    transition: 0.3s;

    &:hover {
        transform: translateY(-10px);
    }
}

.expert-table-container {
    height: 70vh;
}

.expert-card .img-container {
    height: 8rem;
    overflow: hidden;
}

.expert-card .expert-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 11px;
}

.expert-card .text-container .text-title {
    font-weight: 700;
    font-size: 1.1rem;
}

.expert-card .text-container .text-subtitle {
    font-size: 0.9rem;
    color: #141414;
}

.expert-card .expertise-container .expertise-item {
    font-size: 0.95rem;
    font-weight: 600;
    border: 1px solid #141414;
    border-radius: 15px;
}

.expert-card .socials-container .social-icon {
    height: 35px !important;
    width: 35px !important;
    cursor: pointer;
}
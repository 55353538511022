@media screen and (min-width: 1200px) {
  .banner_retail.banner_home h1 {
    width: 100%;
  }
}


.black-input p {
  width: 100% !important;
  margin-top: 0px !important;
  padding: 0;
}


@media screen and (min-width: 768px) {
  .col-md-3-5 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 991px) {
  .col-md-4-5 {
    flex: 0 0 37.333333%;
    max-width: 37.333333%;
  }
}

@media screen and (min-width: 991px) {
  .col-md-7-5 {
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
}

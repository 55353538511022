$yellow-color: #FFC601;
$black-color: #000000;
$white-color: #ffffff;
$light-gray: #EBEBEB;
$dark-gray: #1c1c1c;
$light-black: #2E2E2E;
$gray: #8C8C8C;
$orange-color: #FD8237;
$beige: #FEFBF5;
#main_navbar{
    color: $white-color;
    width: 100vw;
    padding: 0.5em 5em;
    background-color: $black-color;
    border-bottom: 1px solid $white-color;
    z-index: 99;
    @media screen and (max-width: 1200px){
        display: none;
    }
    &.p-fixed{
        position: fixed;
        top: 44px;
        @media screen and (min-width: 1800px){
            height: 4.5em;
        }
    }
    
    @media screen and (min-width: 1800px) {
        padding: 0.51em 17em;
    }
    @media screen and (max-width: 767px) {
       
        padding:10px 15px;
    }
    @media screen and (max-width: 1200px) and (min-width: 768px){
       
        padding:10px 25px;
    }
    @media screen and (max-width: 767px) {
       
       top:38px
    }
    
  
    ul{
        li{
            color: $white-color;
        }
    }
    a{
        color: $white-color;  
        font-size: 15px;
    }
    #brand-logo{
        width: 120px;
    }
    .dropdown-menu{
        background-color: transparent;
        .dropdown-item:focus, .dropdown-item:hover{
           
                color: $black-color;
            
        }
        .dropdown-item.active, .dropdown-item:active{
            background-color: $yellow-color;
        }
        a{
            font-size: 15px;
        }
    }
    .dropdown-toggle::after {
        content: url('../../assets/img/down-arrow.png');
        border-color: transparent;
        position: absolute;
        top: 8px;
        font-size: 13px;
        width: 20px; /* Adjust the width as needed */
        height: 20px; 
      }
    .dropdown-toggle.show::after{
        content: url('../../assets/img/up-arrow.png');
        border-color: transparent;
        position: absolute;
        top: 8px;
//         font-size: 13px;
//         width: 20px; /* Adjust the width as needed */
//   height: 20px; 
    }
    .navbar-nav{
        width: 100%;
        // display: flex;
        // justify-content: space-between;
        padding-left: 40px;
        @media screen and (max-width: 1200px) {
            //do Smth
            padding-left: 0px;
          }
          .nav-item{
            padding-right: 30px;
          
          }
    }
    .nav-links li.drop-items{
        margin-right: 30px;
       
            &::after {

                // content: "";
                font-family: FontAwesome;
                content: "\f0d7";
                margin-left: 10px;
                
            }
            &:hover {
                // .menu_link {
                //     color: $yellow-color;
                // }
             
    
                    &::after {
                        content: "\f0d8";
                        // color: $yellow-color;
                    }
                
            
           
        }
        
    }
   
      .mega-box {
        scale: 0;
        justify-content: center;
        position: absolute;
        left: 0;
        width: 100%;
        padding: 50px 30px;
        top: 25px;
        opacity: 0;
        visibility: hidden;
        z-index: 100;
        @media screen and (max-width: 1200px) and (min-width: 768px) {
            top: 57px;
            padding: 31px 0;
        }
        &.mega-box2{
            width: 100%;
            .content{
                width: 57%;
            }
        }
    }

    .mega-box .content {
        background: #fff;
        border: 1px solid #F3F3F4;
        border-radius: 15px;
        padding: 30px 20px;
        display: flex;
        width: 80%;
        justify-content: flex-start;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
        color: $black-color;

        a {
            color: $black-color;
            text-align: left;
            transition: all 1s ease;

            &:hover {
                color: $black-color;

              
            }
        }

        .has_menu_2 {
            width: 100% !important;

        }

        .nav_head {
            font-size: 1.1em;
            font-weight: 700;
            text-align: left;
            margin-top: 30px;
            @media screen and (max-width: 1500px) and (min-width: 1200px) {
                font-size: 0.9em;
            }
            &.nav_head_2 {
                color: $gray;
                font-size: 1em;
            }
        }

        p {
            text-transform: initial;
            font-weight: 400;
            &.dates{
                font-weight: 400;
                margin-top: 20px;
            }
            // @media screen and (min-width: 1200px) {
            //    &.first_p{

            //        width: 55%;
            //    }
               
                
            // }
        }
    }

    
    .drop-items:hover{
    color: $yellow-color;
    .desktop-item{
        color: $yellow-color;
    }
    .mega-box {
        transition: all 0.2s ease;
        width: 100%;
        opacity: 1;
        visibility: visible;

        // top: 40px;

        padding: 45px 0;
        }
    }
    .mega-box{
        .img_header{
            background-size: cover;
            height: 275px;
            border-radius: 15px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            border: 1px solid transparent;
          
            &.retail_header{
                background-image: url(../img/header/retail-bg.jpg);
            }
            &.bus_header{
                background-image: url(../img/header/bus-bg.jpg);
            }
            &.knw_header{
                background-image: url(../img/header/knowledge-warehouse.jpg);
            }
            &.abt_header{
                background-image: url(../img/header/abt.jpg);
                background-position: center;
            }
            &.job_header{
                background-image: url(../img/header/aptitude.jpg);
            }
            &.aptitude_header{
                background-image: url(../img/header/aptitude.png);
            }
            .img_head{
                color: $white-color;
                margin-bottom: 10px;
                font-size: 1.2em;
            }
            .img_para{
                color: $white-color;
                margin-bottom: 0px;
                line-height: 1.2em;
                font-weight: 400;
            }
        }
       
        .border_div{
            border: 1px solid #E8E5E1;
            border-radius: 10px;
            padding: 30px 20px;
            // margin-bottom: 10px;
            // display: flex;
            height: 130px;
            display: block;
            margin-bottom: 20px;
            &.reasearch{
                border: none;
                &:hover{
                    .nav_head{
                        text-decoration: underline;
                      
                    }
                }
                .nav_head{
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
               
            }
            &:hover{
                border-color: $yellow-color;
            }
            &.mb-44{
                margin-bottom: 20px;
            }
            p{
                margin-bottom: 0px;
                font-size: 13px;
            }
            .nav_head{
                margin: 0px;
                margin-bottom: 7px;
                @media screen and (max-width: 1400px){font-size: 13px;}
                img{
                    width: 110px;
                    height: 30px;
                    
                    object-fit: contain;
                    padding-right: 20px;
                    margin-right: 20px;
                    border-right: 1px solid $yellow-color;
                    margin-bottom: 10px;
                    @media screen and (max-width: 1400px){
                        padding-right: 10px;
                        width: 90px;
                        margin-right: 10px;
                        // object-position: bottom;
                    }
                }
                &.zetta-logo-head{
                    img{
                        width: 120px;
                    }
                }
            }
        }
    }
}
.yellow-button{
    background-color: $yellow-color;
    color: $black-color;
    border-radius: 30px;
    font-size: 17px;
    padding: 5px 30px;
    box-shadow: none;
    border: $black-color;
    border: 1px solid transparent;
    margin-top: 5px;
    @media screen and (max-width: 1200px) {
        //do Smth
        width: fit-content;
    }
    &:hover{
        background-color: $white-color;
        color: $black-color;
        border: 1px solid;
    }

}

#main_navbar{
    
       .nav_1{
        display: flex;
        align-content: center;
        align-items: center;
        .link-yellow{
            &:hover{
                a{
        
                    color: $yellow-color;
                }
            }
        }
       }
       
      
       .wrapper{
        position: relative;
        // max-width: 1300px;
        // padding: 0px 30px;
        // height: 70px;
        // line-height: 70px;
        padding: 5px 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    //   .wrapper .logo a{
    //     color: #f2f2f2;
    //     font-size: 30px;
    //     font-weight: 600;
    //     text-decoration: none;
    //   }
      .wrapper .nav-links{
        display: inline-flex;
        margin-bottom: 0px;
        margin-left: 20px;
      }
      .nav-links li{
        list-style: none;
        margin-right: 30px;
      }
     
    //   .nav-links li a{
    //     color: #f2f2f2;
    //     text-decoration: none;
    //     font-size: 18px;
    //     font-weight: 500;
    //     padding: 9px 15px;
    //     border-radius: 5px;
    //     transition: all 0.3s ease;
    //   }
    //   .nav-links li a:hover{
    //     background: #3A3B3C;
    //   }
      .nav-links .mobile-item{
        display: none;
      }
      .nav-links .drop-menu{
        position: absolute;
        background: #242526;
        width: 180px;
        line-height: 45px;
        top: 85px;
        opacity: 0;
        visibility: hidden;
        box-shadow: 0 6px 10px rgba(0,0,0,0.15);
      }
      .nav-links li:hover .drop-menu,
      .nav-links li:hover .mega-box{
        transition: all 0.3s ease;
        // top: 70px;
        opacity: 1;
        visibility: visible;
        display: flex;
        scale: 1;
      }
      .drop-menu li a{
        width: 100%;
        display: block;
        // padding: 0 0 0 15px;
        // font-weight: 400;
        // border-radius: 0px;
      }
   
    //   .mega-box{
    //     position: absolute;
    //     left: 0;
    //     width: 100%;
    //     padding: 0 30px;
    //     top: 85px;
    //     opacity: 0;
    //     visibility: hidden;
    //   }
      .mega-box .content{
        // background: #242526;
        // padding: 25px 20px;
        // display: flex;
        // width: 100%;
        // justify-content: space-between;
        // box-shadow: 0 6px 10px rgba(0,0,0,0.15);
      }
    //   .mega-box .content .row{
    //     width: calc(25% - 30px);
    //     line-height: 45px;
    //   }
    //   .content .row img{
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    //   }
    //   .content .row header{
    //     color: #f2f2f2;
    //     font-size: 20px;
    //     font-weight: 500;
    //   }
    //   .content .row .mega-links{
    //     margin-left: -40px;
    //     border-left: 1px solid rgba(255,255,255,0.09);
    //   }
    //   .row .mega-links li{
    //     padding: 0 20px;
    //   }
    //   .row .mega-links li a{
    //     padding: 0px;
    //     padding: 0 20px;
    //     color: #d9d9d9;
    //     font-size: 17px;
    //     display: block;
    //   }
    //   .row .mega-links li a:hover{
    //     color: #f2f2f2;
    //   }
    //   .wrapper .btn{
    //     color: #fff;
    //     font-size: 20px;
    //     cursor: pointer;
    //     display: none;
    //   }
    //   .wrapper .btn.close-btn{
    //     position: absolute;
    //     right: 30px;
    //     top: 10px;
    //   }
      
    //   @media screen and (max-width: 970px) {
    //     .wrapper .btn{
    //       display: block;
    //     }
    //     .wrapper .nav-links{
    //       position: fixed;
    //       height: 100vh;
    //       width: 100%;
    //       max-width: 350px;
    //       top: 0;
    //       left: -100%;
    //       background: #242526;
    //       display: block;
    //       padding: 50px 10px;
    //       line-height: 50px;
    //       overflow-y: auto;
    //       box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    //       transition: all 0.3s ease;
    //     }
    //     /* custom scroll bar */
    //     ::-webkit-scrollbar {
    //       width: 10px;
    //     }
    //     ::-webkit-scrollbar-track {
    //       background: #242526;
    //     }
    //     ::-webkit-scrollbar-thumb {
    //       background: #3A3B3C;
    //     }
    //     #menu-btn:checked ~ .nav-links{
    //       left: 0%;
    //     }
    //     #menu-btn:checked ~ .btn.menu-btn{
    //       display: none;
    //     }
    //     #close-btn:checked ~ .btn.menu-btn{
    //       display: block;
    //     }
    //     .nav-links li{
    //       margin: 15px 10px;
    //     }
    //     .nav-links li a{
    //       padding: 0 20px;
    //       display: block;
    //       font-size: 20px;
    //     }
    //     .nav-links .drop-menu{
    //       position: static;
    //       opacity: 1;
    //       top: 65px;
    //       visibility: visible;
    //       padding-left: 20px;
    //       width: 100%;
    //       max-height: 0px;
    //       overflow: hidden;
    //       box-shadow: none;
    //       transition: all 0.3s ease;
    //     }
    //     #showDrop:checked ~ .drop-menu,
    //     #showMega:checked ~ .mega-box{
    //       max-height: 100%;
    //     }
    //     .nav-links .desktop-item{
    //       display: none;
    //     }
    //     .nav-links .mobile-item{
    //       display: block;
    //       color: #f2f2f2;
    //       font-size: 20px;
    //       font-weight: 500;
    //       padding-left: 20px;
    //       cursor: pointer;
    //       border-radius: 5px;
    //       transition: all 0.3s ease;
    //     }
    //     .nav-links .mobile-item:hover{
    //       background: #3A3B3C;
    //     }
    //     .drop-menu li{
    //       margin: 0;
    //     }
    //     .drop-menu li a{
    //       border-radius: 5px;
    //       font-size: 18px;
    //     }
        // .mega-box{
        //   position: static;
        //   top: 65px;
        //   opacity: 1;
        //   visibility: visible;
        //   padding: 0 20px;
        //   max-height: 0px;
        //   overflow: hidden;
        //   transition: all 0.3s ease;
        // }
        // .mega-box .content{
        //   box-shadow: none;
        //   flex-direction: column;
        //   padding: 20px 20px 0 20px;
        // }
        // .mega-box .content .row{
        //   width: 100%;
        //   margin-bottom: 15px;
        //   border-top: 1px solid rgba(255,255,255,0.08);
        // }
        // .mega-box .content .row:nth-child(1),
        // .mega-box .content .row:nth-child(2){
        //   border-top: 0px;
        // }
        // .content .row .mega-links{
        //   border-left: 0px;
        //   padding-left: 15px;
        // }
        // .row .mega-links li{
        //   margin: 0;
        // }
        // .content .row header{
        //   font-size: 19px;
        // }
      }
    //   nav input{
    //     display: none;
    //   }
      
    //   .body-text{
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     width: 100%;
    //     text-align: center;
    //     padding: 0 30px;
    //   }
    //   .body-text div{
    //     font-size: 45px;
    //     font-weight: 600;
    //   }
// }

.navbar{
    background-color: $black-color !important;
    border-bottom: 1px solid $white-color;
    color: $white-color;
    @media screen and (min-width: 1201px) {
        display: none;
    }
    width: 100vw;
    z-index: 99;
    &.p-fixed{
        position: fixed;
        top: 44px;
        @media screen and (width: 768px){
            top: 45px;
        }
        @media screen and (max-width: 767px){
            top: 59px;
        }
       
       
    }
    img{
        width: 120px;
        @media screen and (min-width: 768px) {
            // width: 180px;
            height: 50px;
            object-fit: contain;
            padding: 9px 0 0 0;
            width: 180px;
            object-position: left;
        }
    }
    a{
        color: $white-color;
        &:hover{
            color: $white-color;
        }
        
    }
    .navbar-toggler-icon {
        background-image: none;
        background-color: transparent;
        border: none;
        width: 0;
        height: 0;
        padding: 0;
      }
      
    .navbar-toggler {
      margin-top: 0px;
        background-image: url('../../assets/img/close.png');
      
        background-size: contain;
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
        @media screen and (min-width: 768px) {
            width: 25px;
            height: 25px;
        }
        &.collapsed{
            background-image: url('../../assets/img/menu.png');
        }
      }
    .nav-link.show{
        color: $white-color;
    }
    .nav-links{
       
        &:focus, &:hover{
            color: $black-color;
        }
    }
    .nav-link{
        padding-bottom: 0px;
        @media screen and (min-width: 768px) {
            font-size: 25px;
            padding-bottom: 10px;
        }
    }
    .navbar-brand{
        padding-top: 0px;
    }
    .navbar-nav{
        @media screen and (min-width: 768px){
            padding-top: 20px;
        }
    }
    .dropdown-menu{
        background-color: $black-color;
        padding: 0px;
        .dropdown-item{
            padding-left: 7px;
            font-weight: lighter;
            @media screen and (min-width: 768px) {
                font-size: 20px;
                // padding-bottom: 10px;
            }
            &:hover, &:focus{
                background-color: $black-color;
                color: $yellow-color;
            }
        }
    }

    .dropdown-toggle{
        padding-bottom: 0px;
        @media screen and (min-width: 768px) {
            font-size: 25px;
            padding-bottom: 10px;
        }
        &:after{

            margin-bottom: -3px;
            margin-left: 10px;
        }
    }
    .collapse{
        &.show{
            padding-bottom: 15px;
        }
    }
}
.retail_pages{

   .banner_logo_div{
    @media screen and (max-width: 1200px)and (min-width: 768px){
    display: flex;
    align-items: center;
    }
   }
    .banner_retail.banner_2{
        @media screen and (max-width: 1200px)and (min-width: 768px){
        padding-top: 120px !important;
        }
    }
    .banner_retail.main_banner{
        @media screen and (max-width: 1200px)and (min-width: 768px){
        height: auto !important;
        }
    }
    #banner-logo{
        @media screen and (max-width: 1200px)and (min-width: 768px){
            width: 130px !important;
            height: 60px;
            object-fit: contain;
            margin-bottom: 16px;
        }
    }
.navbar{
    .navbar-toggler{
        // @media screen and (max-width: 767px) {
            margin-top: -2px;
        // }
    }
    .navbar-brand {
        padding-top: 5px;
        padding-bottom: 5px;
    }
   
}

}
.travel_pages{
    .banner_logo_div{
        @media screen and (max-width: 1200px)and (min-width: 768px){
        display: flex;
        align-items: center;
        }
    }
    #banner-logo{
        @media screen and (max-width: 1200px)and (min-width: 768px){
            width: 220px !important;
            height: 40px;
            object-fit: contain;
        }
    }
    .banner_retail.banner_2{
        @media screen and (max-width: 1200px)and (min-width: 768px){
        padding-top: 120px !important;
        }
    }
    .banner_retail.main_banner{
        @media screen and (max-width: 1200px)and (min-width: 768px){
        height: auto !important;
        }
    }
    .navbar{
        .navbar-toggler{
            // @media screen and (max-width: 767px) {
                margin-top: -2px;
            // }
        }
        .navbar-brand {
        padding-top: 18px;
        padding-bottom: 18px;
    }
       
    }
   
    }

    .mob_nav_head{
        color: #8c8c8c;
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 5px;
    }
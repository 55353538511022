$image-height: 70vh;
.slider-box {
	width: 90vw;
	height: $image-height;
}
.image1 {
	background-image: url("../../assets/img/careers/slider_1.JPG");
	background-size: cover;
	background-repeat: no-repeat;
	width: 90vw;
	height: $image-height;
	background-position: center;
}

.image2 {
	background-image: url("../../assets/img/careers/slider_21.JPG");
	background-size: cover;
	background-repeat: no-repeat;
	width: 90vw;
	height: $image-height;
	background-position: top;
}

.image3 {
	background-image: url("../../assets/img/careers/slider_3.JPG");
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: $image-height;
	background-position: center;
}
.image4 {
	background-image: url("../../assets/img/careers/slider_4.JPG");
	background-size: cover;
	background-repeat: no-repeat;
	width: 90vw;
	height: $image-height;
	background-position: center;
}
.image5 {
	background-image: url("../../assets/img/careers/slider_5.JPG");
	background-size: cover;
	background-repeat: no-repeat;
	width: 90vw;
	height: $image-height;
	background-position: center;
}
.image6 {
	background-image: url("../../assets/img/careers/slider_6.JPG");
	background-size: cover;
	background-repeat: no-repeat;
	width: 90vw;
	height: $image-height;
	background-position: center;
}
.slider{
    background-color: #FEFBF5;
}
.slider-section{
    flex: 1;
    justify-content: center;
    width: 90vw;
}
// .slick-prev::before, .slick-next::before{
//     display: none;
// }
.border-al{
    border:1px solid black
}
$yellow-color:#FFC601;
$black-color:#000000;
.tickerMain{
    background-color: $yellow-color;
    padding: 10px;
    text-align: center;
    z-index: 199;
    &.sticky_ticker{
        position: fixed;
        top: 0;
        width: 100%;
    }
    p{

        color: $black-color;
        margin: 0px;
        a{
            margin-left: 5px;
            color: $black-color;
            text-decoration: underline !important;
        }  
    }
}
/* general */
.webinar {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
    background-color: #FEFBF5;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

/* loading section */
.loadingSection {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingSection img {
    width: max(4rem, 7vw);
}

/* container */
.container {
    /* margin-inline: 5vw; */
}

.RestContainer {
    /* margin-inline: 5vw; */
    padding-bottom: 4rem;
}

/* landing */
.landing {
    padding-top: max(9rem, 8vw);
    /* border: 1px solid; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.AutoHeight {
    height: auto;
}

/* header */
.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin-inline: 4vw; */
    gap: 1rem;
}

.header .leftLogo {
    display: flex;
}

.header .leftLogo :first-child {
    /* border: 1px solid red; */
    width: min(23vw, 7rem);
    object-fit: contain;
}

.header .leftLogo .horizontalDivider {
    width: 1px;
    height: min(6vw, 3rem);
    margin: 0.5rem 0.9rem;
    background-color: #000;
}

.header .leftLogo :last-child {
    /* border: 1px solid green; */
    width: min(8vw, 3.5rem);
    object-fit: contain;
}

.header .rightLogo {
    display: flex;
    gap: 1.3rem;
    /* border: 1px solid; */
}

.header .rightLogo * {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.header .rightLogo :first-child img {
    width: min(23vw, 6rem);
    object-fit: contain;
}

.header .rightLogo :last-child img {
    width: min(23vw, 6rem);
    object-fit: contain;
}

.header .rightLogo span {
    font-size: 1.2rem;
    text-align: center;
}


/* main section */
.main {
    display: flex;
    margin-top: 3rem;
}

.main .leftContent {
    width: 60%;
}

.main .rightImg {
    width: 40%;
}

.main .rightImg img {
    width: 100%;
    object-fit: contain;
}

.main .leftContent P:first-of-type {
    margin-top: 1.3rem;
}

.main .leftContent P:last-of-type {
    margin-top: 1rem;
    width: 100%;
}

.main .leftContent .heading {
    width: 80%;
    font-size: max(1.5rem, 2vw);
    font-weight: 750;
}

.main .leftContent p {
    font-size: max(0.9rem, 1.06vw);
    padding-right: 4.8rem;
    font-weight: 450;
}

.landing .readMoreBtn {
    position: absolute;
    bottom: 0;
    width: auto;
    align-self: center;
    font-size: 0.9rem;
    width: 100%;
    opacity: 0.9;
    color: #000;
    text-decoration: underline;
    display: none;
}

.landing .StickBottom {
    bottom: 0;
}

.landing .unStickBottom {
    position: relative;
    background-color: transparent;
}

.main .rightImg img {
    border-radius: 17.17px;
}

.main .rightImg button {
    width: 100%;
    background-color: #FFC50B;
    color: black;
    border: none;
    padding-block: max(0.4rem, 0.6vw);
    font-size: max(0.9rem, 1.3vw);
    font-weight: 450;
    border-radius: 35px;
    margin-top: 1rem;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.3s ease-in-out;

    &:hover {
        background-color: black;
        color: #FFC50B;
        border: 1px solid #FFC50B;
    }
}

/* timer section */
.timerSection {
    margin-top: 5vw;
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
}

.timerSection .countdown {
    text-align: center;
    display: inline-block;
    background-color: #F3F0EB;
    border-radius: 16px;
    padding: 0.8rem;
}

.timerSection .countdown ul {
    padding: 0;
    display: flex;
}

.timerSection .countdown .dateText {
    color: gray;
}

.timerSection .countdown li {
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding-inline: max(1.5vw, 0.7em);
    text-transform: uppercase;
}

.timerSection .countdown li span {
    display: block;
    font-size: max(1.9rem, 4.5vw);
}

/* feataured section */
.featured {
    margin-top: 2.5rem;
    width: 84%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: min(4vw, 3rem);
    row-gap: 1.5rem;
}

.featured .card {
    background-color: #F3F0EB;
    padding: 1rem;
    display: flex;
    gap: 1.5rem;
    border-radius: 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 6px 10px 0px;
    }
}

.featured .card .imgContainer {
    display: flex;
    align-items: center;
}

.featured .card .imgContainer img {
    width: max(5rem, 7vw);
    height: max(5rem, 7vw);
    object-fit: cover;
    background-color: white;
    border-radius: 50%;
}

.featured .card .contentContainer {
    margin-block: auto;
}

.featured .card .contentContainer h4 {
    font-size: max(1vw, 1rem);
    display: inline;
    font-weight: 430;
    border-bottom: 1px solid #FF6B00;
    padding-bottom: 2px;
}

.featured .card .contentContainer h3 {
    margin-top: 1rem;
    font-size: max(1.3rem, 1.4vw);
    font-weight: 530;
}

.featured .card .contentContainer .socialIconsContainer {
    display: flex;
    gap: max(5px, 0.4vw);
}

.featured .card .contentContainer .socialIconsContainer .socialIcon {
    width: 30px !important;
    height: 30px !important;
    filter: grayscale(100%);

    &:hover {
        filter: grayscale(0%);
    }
}

/* about section */
.about {
    margin-top: 2rem;
}

.about .speakerTitle {
    font-weight: 700;
    margin-top: 0.8rem;
}

.about div {
    margin-top: 1rem;
}

.about p {
    font-size: max(0.9rem, 1.06vw);
}


/* guest speaker button */
.GuestBtnContainer {
    display: flex;
    margin-block: 2.5rem;
}

.GuestBtn {
    margin-inline: auto;
    background-color: #FFC50B;
    color: black;
    border: none;
    padding-inline: 1.5rem;
    font-size: 1.1rem;
    font-weight: 650;
    border-radius: 35px;
    margin-top: 1rem;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.3s ease-in-out;

    &:hover {
        background-color: black;
        color: #FFC50B;
        border: 1px solid #FFC50B;
    }
}

/* recommended talks section */
.recommended {
    margin-top: 2rem;
}

.recommended h4 {
    margin-bottom: 1rem;
    font-weight: 600;
}

.recommended .cardContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.3rem;
}

.recommended .cardContainer .card {
    background-color: #F3F0EB;
    border-radius: 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    height: 100%;

    &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 6px 10px 0px;
        transform: translateY(-4px);
    }
}

.recommended .cardContainer .card .imgContainer img {
    width: 100%;
    border-radius: 16px 16px 0 0;
}

.recommended .cardContainer .card .contentContainer {
    padding: 1rem 1rem 0.8rem 1rem
}

.recommended .cardContainer .card .contentContainer h4 {
    color: black;
    font-size: max(1rem, 1.2vw);
    font-weight: 600;
    margin-bottom: 3rem;
}

.recommended .cardContainer .card .contentContainer span {
    color: #9A9A9A;
    font-size: max(1.05vw, 0.8rem);
}

/* i-frame modal */
.iFrameModal {
    max-width: 80vw;
}

.iFrameModal h3 {
    text-align: center;
    margin-bottom: 1.1rem;
    font-weight: 700;
    font-size: max(1rem, 1.8vw);
}

.iFrameModal .frameContainer {
    display: flex;
    gap: 1rem;
}

.iFrameModal .frameContainer iframe {
    max-height: 430px;
}


/* media queries */
@media (width <= 1280px) {
    .main .rightImg {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


@media (width <= 768px) {
    .header {
        justify-content: center;
    }

    .header .rightLogo span {
        font-size: 1rem;
        line-height: 1rem;
    }
    
    .main {
        flex-direction: column-reverse;
        margin-top: 1.5rem;
    }
    
    .main :first-child {
        width: 100%;
    }
    
    .main :last-child {
        width: 70%;
        margin: auto;
    }

    .main .leftContent .heading {
        width: 100%;
    }
    
    .main .leftContent {
        margin-top: 1rem;
    }
    
    .main .leftContent p {
        padding-right: 0;
    }

    .main :last-child {
        width: 100%;
    }

    .landing {
        height: auto;
    }

    .timerSection {
        margin-top: 1.5rem;
    }

    .timerSection .countdown li {
        font-size: 1rem;
    }

    .featured {
        width: 100%;
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }

    .recommended .cardContainer {
        grid-template-columns: repeat(2, 1fr);
    }

    .iFrameModal .frameContainer {
        flex-direction: column;
    }
}

/* special breakpoint to add margin top */
@media (width <= 582px) {
    .timerSection .countdown ul {
        margin-top: 1vw;
    } 
    
    .iFrameModal {
        margin: auto;
    }
}

@media (width <= 480px) {
    .header {
        gap: 0.8rem;
    }
    
    .header .rightLogo span {
        font-size: 0.8rem;
    }
    
    .main .leftContent P:first-of-type {
        margin-top: 0.8rem;
    }

    .main .leftContent P:last-of-type {
        margin-top: 0.7rem;
    }

    .timerSection .countdown .dateText {
        font-size: 0.8rem;
    }

    .featured .card .contentContainer h3 {
        font-size: 1.3rem;
    }

    .recommended .cardContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (width <= 320px) {
    .main :last-child {
        width: 100%;
    }
}

$yellow-color: #FFC601;
$black-color: #000000;
$white-color: #ffffff;
$light-gray: #EBEBEB;
$dark-gray: #1c1c1c;
$light-black: #2E2E2E;
$gray: #8C8C8C;
$orange-color: #FD8237;
$beige: #FEFBF5;

body {
    overflow-x: hidden;
    background-color: $beige;
}

.bg-beige {
    background-color: $beige;
}

.retail_pages {
    position: relative;
}

.route-report {
    .btn-outline-light {
        --bs-btn-hover-bg: #FFC601 !important;
        --bs-btn-hover-border-color: #FFC601 !important;
        --bs-btn-hover-color: #000 !important;
    }

    .region {
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    svg {
        path {
            outline: none !important;
        }
    }

    .row {
        color: #fff !important;
    }
}

.smallHead {
    font-size: 1.2em;

    span {
        color: $yellow-color;
    }
}

.bigHead {
    font-size: 1.7em;
    font-weight: 700;
}

.hover-text {
    cursor: pointer;

    &:hover {
        text-decoration: underline !important;
    }
}

.remove-white-bg {
    background-color: transparent !important;
    border: none !important;
}

.whiteColor {
    color: $white-color;
}

.text-yellow {
    color: $yellow-color;
}

.mt-7 {

    margin-top: 5rem;

    @media screen and (max-width: 1200px) {
        margin-top: 2rem;
    }
}

.modal-close-icon {
    position: absolute;
    right: -8px;
    background-color: #bb2124;
    border-radius: 50%;
    top: -12px;
    color: white;
    padding: 2.5px;
    font-size: 1.7rem !important;
    z-index: 9999;
    cursor: pointer;

    &:hover {
        background-color: #941a1c;
    }
}

.black-bg {
    background-color: $black-color;

    &.partner_capterra {
        background-color: $white-color;

        .travel_recog {
            background-color: $beige;
        }
    }

    &.hotel_bg_1 {
        background-color: $beige;

        .recog_div {
            background-color: $beige;
            // padding-top: 0px;
        }
    }

    &.whyus_captera {
        background-color: $beige;
        border-top: 1px solid;
        border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

        .recog_div {
            background-color: $beige;
            // padding-top: 0px;
        }
    }

    &.brio_inside {
        background-color: $beige;
        border-top: 1px solid;
        border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

        .recog_div {
            background-color: $beige;
        }
    }

    &.hotel_inside {
        background-color: $beige;
        // border-top: 1px solid;
        // border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

        .recog_div {
            background-color: $beige;
        }
    }
}

h1 {
    font-size: 3.0em;
    font-weight: 700 !important;

    @media screen and (max-width: 1023px) {
        font-size: 2em;

    }

    @media screen and (max-width: 767px) {
        font-size: 1.2em;

    }

    @media screen and (max-width: 1400px) and (min-width: 1201px) {
        font-size: 2.5em;

    }

    @media screen and (max-width: 1200px) and (min-width: 1024px) {
        font-size: 2.5em;

    }

}

p {
    font-weight: 500;

    @media screen and (max-width: 767px) {
        font-size: 0.8em;

    }
}

iframe {
    width: 100%;

    @media screen and (max-width: 767px) {
        height: 200px;
    }

    @media screen and (max-width: 1023px) and (min-width: 768px) {
        height: 400px;
    }

    @media screen and (max-width: 1200px) and (min-width: 1024px) {
        height: 550px;
    }
}

.yellow-button {
    background-color: $yellow-color;
    color: $black-color;
    border-radius: 30px;
    font-size: 17px;
    font-weight: 600 !important;
    padding: 3px 30px 5px 30px;
    box-shadow: none;
    border: 1px solid $yellow-color;
    margin-top: 5px;
    font-weight: 600;
    text-decoration: none;

    @media screen and (max-width: 1200px) {
        //do Smth
        width: fit-content;
    }

    &:hover {
        background-color: $white-color;
        color: $black-color;
        border-color: $black-color;

    }

}

.white-button {
    background-color: $beige;
    color: $black-color;
    border-radius: 30px;
    font-size: 17px;
    padding: 3px 30px 5px 30px;
    box-shadow: none;
    border: 1px solid $black-color;
    margin-top: 5px;
    font-weight: 600;
    text-decoration: none;

    @media screen and (max-width: 1200px) {
        //do Smth
        width: fit-content;
    }

    &:hover {
        background-color: $black-color;
        color: $white-color;
        border-color: $black-color;

    }

}


.black-button {
    background-color: $black-color;
    color: $white-color;
    border-radius: 15px;
    font-size: 17px;
    padding: 3px 30px 5px 30px;
    box-shadow: none;
    border: 1px solid $black-color;
    margin-top: 5px;
    font-weight: 600;

    @media screen and (max-width: 1200px) {
        //do Smth
        width: fit-content;
    }

    &:hover {
        background-color: $black-color;
        color: $white-color;
        border-color: $white-color;
    }

    img {
        width: 22px;
        margin-left: 7px;
    }
}

.transparent-button {
    background-color: transparent;
    color: $white-color;
    // border-radius: 30px;
    font-size: 17px;
    padding: 5px 30px 10px 30px;
    box-shadow: none;
    border-radius: 0px;
    border: none;
    margin-top: 5px;

    @media screen and (max-width: 1200px) {
        //do Smth
        width: fit-content;
    }

    &:hover {
        background-color: transparent;
        // color: $black-color;
        border-bottom: 1px solid;
    }

    img {
        width: 30px;
        margin-left: 11px;
    }
}

.this_div {
    position: sticky;
    top: 0;
    width: 100%;

    img {
        width: 120px;
    }
}

.banner_retail {
    background-color: $black-color;
    color: $white-color;
    position: relative;
    padding: 120px 0 50px 0;

    // height: 80vh;
    @media screen and (min-width: 1200px) {
        // height: 100vh;
    }

    a {
        cursor: pointer;
    }

    .banner_logo_sticky {
        // position: sticky;
        width: 100%;
        // background-color: $black-color;
        // top: 0;
    }

    .banner_logo_div {
        padding-top: 20px;

        ul {
            list-style-type: none;

            li {
                display: inline-block;
                margin-left: 20px;

                a {
                    color: $white-color;
                    text-decoration: none;
                    font-size: 0.8em;
                    text-transform: uppercase;

                    &:hover {
                        color: $yellow-color;
                    }
                }

                &.has_menu {

                    &::after {

                        // content: "";
                        font-family: FontAwesome;
                        content: "\f0d7";
                        margin-left: 10px;
                    }

                    &:hover {
                        .menu_link {
                            color: $yellow-color;
                        }

                        &::after {
                            content: "\f0d8";
                            color: $yellow-color;
                        }
                    }

                }
            }
        }
        &.zetta-banner-div{
            #banner-logo{
                width: 160px;
            }
           
        }
    }

    #banner-logo {
        width: 200px;
        margin-bottom: 30px;

        @media screen and (max-width: 1200px) and (min-width: 768px) {
            margin-bottom: 20px;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    h1 {

        margin-bottom: 20px;

    }

    p {
        margin-bottom: 30px;
    }

    video {
        width: 84%;
        height: auto;
        margin-top: 38px;

        @media screen and (max-width: 1023px) {
            width: 100%;
            margin-top: 10%;

        }
    }

    .transparent-button {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 30px;
        border-bottom: 1px solid $black-color;

        &:hover {
            border-color: $white-color;
        }
    }

    &.banner_home {
        padding-top: 200px;
        height: auto;

        p {
            margin-top: 20px;
            margin-bottom: 0px;
        }

        video {
            width: 100%;
        }

        .transparent-button {
            color: $yellow-color;
            padding-left: 0px;
            padding-right: 0px;
            display: flex;
            align-items: center;
            margin-left: 0px;

            span {
                padding-left: 5px;

            }

            &:hover {
                border-color: $yellow-color;
            }

            &:focus-visible,
            &:active {
                background-color: transparent !important;
                box-shadow: none !important;
            }
        }

        @media screen and (min-width: 1200px) {
            h1 {
                width: 70%;
            }

            p {
                width: 60%;
                margin-top: 20px;
            }

            video {
                width: 80%;
            }



        }


    }
  
}

.mainInsights {
    padding: 30px 20px;
    background-color: $black-color;
    border-top: 1px solid $white-color;

    @media screen and (max-width: 767px) {
        padding: 30px;

    }

    p {
        margin-bottom: 0px;
        color: $white-color;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        // line-height: 1.4em; /* Adjust as needed */
        // max-height: 2.8em; /* line-height * number of lines */
    }

    a {
        color: $white-color;
        text-decoration: none;
    }

    .insight_div {
        border-left: 1px solid $yellow-color;

        @media screen and (max-width: 1023px) {
            margin-bottom: 10px;

        }
    }
}

.retail_slider {


    background-color: $black-color;

    .slick-slider {
        margin-top: 30px;
    }

    .slider_card {
        // width: 150px;
        // height: 150px;
        display: flex !important;
        justify-content: center;
        outline: none;

        img {
            width: 150px;
            object-fit: contain;
            height: 60px;

            @media screen and (max-width: 1200px) {
                width: 100px;
                padding-right: 20px;
            }
        }
    }

    .inner_ret_slider {
        padding: 50px 0;
        background-color: $beige;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        &.slide_1{
            border-radius: 0px;
        }
    }

    .bigHead {
        @media screen and (max-width: 767px) {
            padding: 0 20px;
        }
    }

    &.all_client {

        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        .inner_ret_slider {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            background-color: $beige;
            border-top: 1px solid;
            border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;
        }


    }


}

.study_mat_text {
    font-size: 1.2em;

    @media screen and (max-width: 768px) {
        font-size: 0.9em;
    }
}

.leadership_section {
    background-color: $black-color;
    padding: 50px 0;

    .headWrap {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bigHead {

        display: inline-block;
        background-color: $black-color;
        padding: 7px 30px;
        // &::before{
        //     content: "";
        //     position: absolute;
        //     width: 100%;
        //     left: 0%;
        //     background-color: $white-color;
        //     height: 1px;
        //     top: 50%;
        //     // z-index: -1;
        // }
    }

    .card {
        background-color: $dark-gray;
        padding: 20px 40px;
        border-radius: 30px;
        margin-bottom: 40px;

        .leader_img_div {
            img {

                width: 100%;

                @media screen and (min-width: 1200px) {
                    height: 500px;
                    object-fit: contain;
                }

                @media screen and (max-width: 1023px) and (min-width:768px) {
                    transform: scale(0.8);
                }
            }

        }

        span {
            color: $light-black;
            font-size: 5.1em;
            font-weight: 800;
            position: absolute;
            top: 9%;
            left: 0px;

            &.t_top {
                top: 13%;
            }

            @media screen and (max-width: 1400px) and (min-width:1201px) {
                font-size: 4em;
            }
        }

        p {
            color: $white-color;
        }

        .leader_content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 30px;
            position: relative;

            .leader_desc {
                position: relative;
                margin-bottom: 30px;

                &::after {
                    content: "";
                    position: absolute;
                    background-color: $yellow-color;
                    width: 100px;
                    height: 1px;
                    bottom: -12px;
                    left: 0px;
                }
            }

            .leader_name {
                font-size: 1.8em;
                font-weight: 800;
            }

            i {
                font-size: 1.6em;

                margin-right: 15px;
                color: $white-color;

                &:hover {
                    color: $yellow-color;
                }
            }
        }

    }
}

.founderVideo {
    padding: 50px 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: $white-color;

    iframe {
        width: 80%;
        height: 580px;
        border-radius: 40px;

        @media screen and (max-width: 767px) {
            height: 200px;
        }

        @media screen and (max-width: 1023px) and (min-width: 768px) {
            height: 400px;
        }

        @media screen and (max-width: 1200px) and (min-width: 1024px) {
            height: 550px;
        }

        @media screen and (max-width: 1400px) and (min-width: 1201px) {

            height: 580px;
        }
    }

    .video_desc {
        font-size: 1.5em;

        @media screen and (max-width: 767px) {
            font-size: 1em;
        }

        margin-bottom: 40px;
    }

    .video_header {
        margin-bottom: 20px;
    }

    .video_info {
        align-items: center;

        p {
            margin-bottom: 0px;

            @media screen and (max-width: 767px) {
                margin-bottom: 20px;
            }
        }
    }
}

.recog_div {
    padding: 50px 0 50px 0;
    background-color: $beige;
    // border-bottom-left-radius: 30.recog_div

    .row {
        @media screen and (max-width: 767px) {
            justify-content: center;
        }
    }

    .col-md-6 {
        @media screen and (max-width: 1200px) and (min-width: 768px) {
            margin-bottom: 30px;
        }
    }

    .recog_img_div {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 767px) {
            margin-bottom: 20px;
            box-shadow: 1px 2px 4px gray;
            padding: 30px 20px;
        }

        @media screen and (max-width: 1200px) and (min-width: 768px) {
            margin-bottom: 30px;
            box-shadow: 1px 2px 4px gray;
            padding: 30px 40px;
            height: 100%;
        }

        img {
            text-align: center;
            width: 100%;
            height: 85px;
            object-fit: contain;
            transform: scale(0.7);
        }
    }

    .award_desc {
        font-weight: 700;
        text-align: center;
        font-size: 1.1em;
        margin-top: 10px;
        margin-bottom: 10px;

        @media screen and (min-width: 1200px) {
            // height: 42px;
        }

        @media screen and (max-width: 767px) {
            font-size: 1em;

        }
    }

    i {
        color: #FFC50B;
        font-size: 9px;
        padding-right: 5px;
    }

    .award_name {
        margin: 0;
        // padding-left: 10px;

        @media screen and (max-width: 767px) {
            padding-top: 5px;
        }

    }

    &.travel_recog {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;

        img {
            height: 50px;
        }
    }

    .award_cat {
        font-size: 1em;
        text-align: center;
    }

    &.recog_2 {
        border-top: 1px solid;
        border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

        .award_desc {

            height: auto;
            margin-bottom: 0px;
            margin-top: 0px;
        }

        .row {
            justify-content: center;
            padding-bottom: 30px;
        }



    }

}

.investor_page_section {
    border-radius: 0px;
    background-color: $beige;
    border-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;

    .recog_div {
        background-color: $beige;
    }
}

.home_recog {
    background-color: $beige;
    border-radius: 0px;

    .recog_div {
        background-color: $beige;
    }
}

.p_number_div {
    padding: 50px 0 50px 0;
    background-color: $black-color;

    .p_div {
        margin-bottom: 30px;
    }

    .card {
        background-color: $black-color;
        border: 1px solid $gray;
        border-radius: 20px;
        padding: 20px 40px 7px 40px;
        height: 100%;


        .p_number {
            font-size: 4.8em;
            color: $yellow-color;
            margin-bottom: 0px;
            font-weight: 800;

            @media screen and (max-width: 767px) {
                font-size: 2.8em;
            }

            @media screen and (max-width: 1023px) and (min-width: 768px) {
                font-size: 2.7em;
            }

            @media screen and (max-width: 2000px) and (min-width: 1024px) {
                font-size: 3.6em;
            }
        }

        .p_desc {
            font-size: 1.5em;
            font-weight: 800;
            color: $white-color;
        }

        &.padding_small {
            padding-left: 20px;
            padding-right: 10px;

            .p_desc {
                font-size: 1.3em;
            }

            .p_number {
                font-size: 4.5em;

                @media screen and (max-width: 767px) {
                    font-size: 2.8em;
                }

                @media screen and (max-width: 1023px) and (min-width: 768px) {
                    font-size: 2.7em;
                }

                @media screen and (max-width: 2000px) and (min-width: 1024px) {
                    font-size: 3.6em;
                }
            }

            @media screen and (max-width: 767px) {
                padding: 20px 40px 7px 40px;
            }
        }
    }

    .p_text {
        font-size: 1.5em;
        color: $white-color;
        font-weight: 800;

        @media screen and (max-width: 1200px) {
            margin-bottom: 0px;
        }

    }

    .transparent-button {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 30px;
        border-bottom: 1px solid $black-color;

        &:hover {
            border-color: $white-color;
        }

        @media screen and (min-width: 1200px) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: left;
        }
    }

    .p_content_div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    }
}

.ra_single_div {
    padding: 50px 0;

    img {
        width: 100%;
        border: 5px solid $yellow-color;
        border-radius: 20px;
        box-shadow: 2px 2px 5px $gray;
    }

    .ra_single_head {
        font-size: 3.2em;
        font-weight: 700;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 1023px) {
            font-size: 1.5em;
            margin-top: 20px;
        }

        @media screen and (max-width: 1200px) {
            font-size: 2.5em;
            // margin-top: 20px;
        }

        @media screen and (max-width: 767px) {
            font-size: 1.4em;
            // margin-top: 20px;
        }
    }

    .ra_single_row {
        display: flex;
        align-items: center;
    }
}

.testimonials {
    padding: 50px 0;
    background-color: $beige;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;

    .card {
        border: 0px;
        background-color: $beige;

        .test_content {
            background-color: #F3F0EB;
            padding: 0px 60px 30px 60px;
            border: 1px solid #DDDDDD;
            border-radius: 30px;
            margin: 0px 5px;
        }

        .tes_img {
            // width: 100px;
            // height: 100%;
            text-align: center;
            display: flex;
            justify-content: center;

            img {
                width: 200px;
                height: 100px;
                object-fit: contain;
            }
        }

        .tes_para {
            font-size: 1.2em;

        }

        .tes_aut {
            display: flex;
            align-items: center;
            margin-top: 30px;

            img {
                width: 50px;
                height: 50px;
                object-fit: contain;

                @media screen and (min-width: 1200px) {
                    margin-left: 50px;
                }
            }

            p {
                margin-bottom: 0px;

                &.tes_name {
                    font-weight: 800;
                }
            }

            .tes_det {
                padding-left: 20px;
            }
        }
    }

    .slick-prev {
        position: absolute;
        bottom: 9px;
        right: 50px;
        top: auto;
        left: auto;
        z-index: 1;

        &::before {
            color: #000;
        }

        @media screen and (max-width: 767px) {
            bottom: -40px;
        }
    }

    .slick-next {
        position: absolute;
        bottom: 9px;
        right: 16px;
        top: auto;
        left: auto;
        z-index: 1;

        &::before {
            color: #000;
        }

        @media screen and (max-width: 767px) {
            bottom: -40px;
        }
    }
}

.travel-insights {
    background-color: $black-color;
    border-top: 1px solid $white-color;
    padding: 30px 0;

    .bus_insight_class {
        text-align: center;

        .bus_insight_heading {
            color: $white-color;
        }

        p {
            color: $white-color;
            font-size: 0.9em;
            margin-bottom: 0px;

            span {
                color: $yellow-color;
            }
        }
    }

    .slick-next {
        right: -50px;

        @media screen and (max-width: 1024px)and (min-width: 768px) {
            right: -30px;
        }
    }

    .slick-prev {
        left: -50px;

        @media screen and (max-width: 1024px)and (min-width: 768px) {
            left: -30px;
        }
    }
}

.bus_video {
    padding: 50px 0;
    border-top: 1px solid;
    background-color: $beige;
    border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

    iframe {
        width: 100%;
        height: 445px;
        border-radius: 15px;
        box-shadow: 0px 0px 4px $light-black ;

        @media screen and (max-width: 767px) {
            width: 100%;
            height: 200px;
        }

        @media screen and (max-width: 1023px) and (min-width: 768px) {
            width: 100%;
            height: 256px;
        }

        @media screen and (max-width: 1200px) and (min-width: 1024px) {
            width: 100%;
            height: 356px;
        }

        @media screen and (max-width: 1400px) and (min-width: 1201px) {

            height: 470px;
        }
    }

    p {
        margin-bottom: 30px;
    }
}

.why_section {
    padding: 50px 0;
    background-color: $beige;

    .col-md-6 {
        margin-bottom: 30px;
    }

    .why_card {
        display: flex;
        margin-bottom: 30px;
        // border-right: 1px solid;
        // border-image: linear-gradient( #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;
        background: #F3F0EB;
        border-radius: 30px;
        padding: 30px;
        height: 100%;

        @media screen and (max-width: 1200px) and (min-width: 768px) {
            flex-direction: column;
            align-items: flex-start;

            .why_card_1 {
                margin-bottom: 20px;
            }
        }

        .why_card_1 {
            margin-right: 20px;
            // width: 74px;
            height: 30px;
            // background-color: #FFC601;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            i {

                font-size: 15px;
                width: 30px;
                height: 30px;
                background: $yellow-color;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

            }
        }

        .why_card_2 {
            .card_head {
                font-size: 1.5em;
                font-weight: 600;

                @media screen and (max-width: 767px) {
                    font-size: 1.2em;

                }
            }

            button {
                color: $black-color;
                border-bottom: 1px solid $black-color;
                padding-left: 0px;
                padding-right: 0px;
                border-color: $black-color;
                outline: none;
                box-shadow: none;

                i {
                    color: $black-color;
                }

                &:hover {
                    border-color: $yellow-color;
                }

                &:focus-visible {
                    background-color: transparent;
                    outline: none;
                    box-shadow: none;
                }
            }

            .transparent-button img {
                width: 20px;
            }
        }
    }

    &.partner_synergy {
        background-color: $beige;
        border-top: 1px solid;
        border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

        .why_card {
            flex-direction: column;
            background-color: #F3F0EB;

            .why_card_1 {
                justify-content: flex-start;
                height: auto;

                img {
                    width: 70px;
                    margin-bottom: 20px;
                }
            }

            .why_card_2 {
                button {
                    padding-left: 20px;
                    padding-right: 20px;
                    border-color: $yellow-color;

                    @media screen and (max-width: 768px) {
                        font-size: 0.9em;

                    }

                    @media screen and (max-width: 900px) and (min-width: 800px) {
                        padding-left: 15px;
                        padding-right: 15px;

                    }
                }
            }

            .why_card_3 {
                @media screen and (min-width: 1201px) {
                    height: 70px;

                }

                @media screen and (max-width: 1200px) and (min-width: 768px) {
                    height: 120px;

                }
            }
        }

    }

    &#key-features {
        .transparent-button {
            display: flex;

            span {
                padding-left: 10px;
            }
        }
    }
}

.click_button {
    padding: 50px 0;
    background-color: $black-color;
}

.about_banner {

    background-color: $black-color;
    padding: 200px 0 100px 0;
    color: $white-color;

    // height: 80vh;
    .banner_logo_div {
        padding-top: 20px;

        ul {
            list-style-type: none;

            li {
                display: inline-block;
                margin-left: 20px;

                a {
                    color: $white-color;
                    text-decoration: none;
                    font-size: 0.8em;
                    text-transform: uppercase;
                }
            }
        }
    }

    #banner-logo {
        width: 200px;
        margin-bottom: 30px;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    h1 {

        margin-bottom: 20px;
        font-size: 1.2em;
        position: relative;
        font-weight: 400;

        &::after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 0px;
            width: 100%;
            height: 2px;
            background-color: $yellow-color;
        }

    }

    p {
        margin-bottom: 30px;
        font-size: 1.4em;

        @media screen and (min-width: 1200px) {
            &.about_para {

                width: 70%;
            }

        }
    }

    video {
        width: 70%;
        height: auto;

        @media screen and (max-width: 1023px) {
            width: 100%;
            margin-top: 10%;

        }
    }

    .transparent-button {
        color: $yellow-color;
        padding-left: 0px;
        padding-right: 0px;
        font-size: 1.3em;
    }

    .about_para_2 {
        margin-bottom: 0px;
        font-weight: 300;
    }

    .about_card_div {
        width: 100%;
        margin-top: 100px;

        .about_card {
            width: 90%;

            .about_head {
                font-size: 1.3em;

                a {
                    color: $yellow-color !important;
                    text-decoration: none;

                }
            }

            .about_para_3 {
                padding-top: 15px;
                margin-top: 15px;
                // border-top: 1px solid $yellow-color;
                font-size: 1em;
                position: relative;

                &::before {
                    content: "";
                    background-color: $white-color;
                    width: 60%;
                    height: 1px;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    &.abt_img {
        background-image: url(../img/about/bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.about_why {
    padding: 50px 0;
    background-color: $beige;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    a {
        &:hover {
            .card {

                border: 2px solid $yellow-color;
                box-shadow: 0px 0px 9px #ececec;
                ;
            }
        }
    }

    .card {
        border: 1px solid #D3D3D3;
        border-radius: 30px;
        display: flex !important;
        background-color: $beige;
        align-items: center;
        // justify-content: center;
        padding: 30px 20px 30px 20px;
        margin: 30px;
        height: 100%;

        @media screen and (min-width: 1200px) {
            height: 350px;
        }

        @media screen and (max-width: 768px) {
            margin: 30px 0px;

        }

        @media screen and (max-width: 1023px)and (min-width: 768px) {
            margin: 30px 20px;
            height: 370px;
        }

        @media screen and (max-width: 1200px)and (min-width: 1024px) {
            margin: 30px 20px;
            height: 400px;
        }
    }

    img {
        width: 120px;
        height: 120px;
        object-fit: contain;

        @media screen and (max-width: 1023px)and (min-width: 768px) {
            width: 90px;
            height: 90px;
        }
    }

    .a_w_head {
        font-size: 1.4em;
        text-align: center;
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .a_w_para {
        text-align: center;
        // font-size: 0.7em;
    }

    .slick-prev {
        position: absolute;
        top: auto;
        bottom: -5%;
        left: 48%;

        @media screen and (max-width: 767px) {
            left: 41%;
        }

        &:before {
            color: $black-color;
        }

        &::after {
            color: $black-color;
        }
    }

    .slick-next {
        position: absolute;
        top: auto;
        bottom: -5%;
        right: 48%;

        @media screen and (max-width: 767px) {
            right: 39%;
        }

        &:before {
            color: $black-color;
        }

        &::after {
            color: $black-color;
        }

        @media screen and (max-width: 1200px)and (min-width: 768px) {
            right: 45%;
        }

    }

    .smallHead {
        span {
            font-weight: 600;
        }
    }
}

.about_firm {
    background-color: $black-color;
    padding: 50px 0;

    .firm_para {
        font-size: 1.4em;
        margin-bottom: 50px;
    }

    .card {
        background-color: transparent;
        border: 1px solid #6565657a;
        border-radius: 15px;
        background-color: #2a2a2a4f;
        padding: 50px 30px;

        .firm_head_1 {
            color: $yellow-color;
            margin-bottom: 30px;
        }

        p {
            color: $white-color;
            line-height: 1.8em;
            font-weight: 400;
            margin-bottom: 0px;

            @media screen and (min-width: 1200px) {
                width: 80%;
            }
        }

        ul {
            padding-left: 1em;
            color: $white-color;

            li {
                &::marker {
                    color: $yellow-color;
                }
            }

        }

    }

    img {
        border: 1px solid #6565657a;
        border-radius: 15px;
        width: 100%;
        margin-top: 64px;
    }

    .first_div {

        img {


            margin-left: -44px;

            @media screen and (max-width: 1200px) {
                margin-left: 0px;
                margin-bottom: 30px;
                margin-top: 0px;
            }

        }
    }

    .second_div {
        margin-top: 75px;

        @media screen and (max-width: 768px) {

            margin-top: 100px;
        }

        img {
            width: 100%;
            margin-right: -44px;

            @media screen and (max-width: 768px) {
                margin-left: 0px;
                margin-bottom: 30px;
                margin-top: 0px;
            }
        }

        .card {
            margin-left: -15px;
            padding-bottom: 30px;
        }
    }

}

.about_leadrrship {
    padding: 50px 0;
    background-color: $black-color;

    .leader_img_div {
        .card {
            margin-top: 75px;
            background-color: transparent;
            border: 1px solid $white-color;
            border-radius: 20px;
            padding: 5px;

            .img_card {
                // background-color: $white-color;
                border-radius: 20px;
                position: relative;
                width: 100%;
                height: 300px;

                @media screen and (max-width: 1200px) and (min-width: 1024px) {
                    height: 330px;
                }

                img {
                    position: absolute;
                    width: 100%;
                    height: 435px;
                    // object-fit: contain;
                    object-fit: contain;
                    top: -33%;
                }
            }

        }

        .leader_name {
            font-size: 1.8em;
            font-weight: 600;
            color: $white-color;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 0px;

            @media screen and (max-width: 767px) {
                margin-top: 35px;
            }
        }

        .leader_desg {
            color: $white-color;
            text-align: center;
            font-size: 0.7em;
            font-weight: 300;
            margin-bottom: 30px;
        }
    }

    .leader_data {
        margin-top: 75px;

        p {
            color: $white-color;
            margin-bottom: 50px;
        }

        ul {
            color: $white-color;

            li {
                margin-bottom: 30px;
            }
        }

        .l_card {
            background-color: #101010;
            border: 1px solid #6565657a;
            padding: 30px;
            border-radius: 15px;
        }

        iframe {
            width: 100%;
            border-radius: 15px;
            height: 500px;

            @media screen and (max-width: 767px) {
                height: 200px;
            }

            @media screen and (max-width: 1023px) and (min-width: 768px) {
                height: 400px;
            }

            @media screen and (max-width: 1200px) and (min-width: 1024px) {
                height: 550px;
            }
        }
    }
}

.abt_people {
    padding: 50px 0;
    position: relative;

    p {
        text-align: center;
        margin: 0 auto;

        @media screen and (min-width: 1200px) {
            width: 80%;

        }
    }

    .img_people {
        padding: 0 10px;
        outline: none;
        height: 220px;

        @media screen and (max-width: 1200px) {
            height: 160px;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media screen and (max-width: 1200px) {
            height: 160px;
        }

        // margin: 30px;
    }

    .gal_1 {
        margin-bottom: 15px;
    }

    .yellow-button {
        position: absolute;
        bottom: 21%;
        right: 8%;

        @media screen and (max-width: 767px) {
            font-size: 12px;
            padding-top: 7px;
            bottom: 25%;
        }

        @media screen and (max-width: 1200px) and (min-width: 1024px) {

            bottom: 24%;
        }

        @media screen and (min-width: 1700px) {
            right: 17%;
        }

    }
}

.abt_impact {
    padding: 50px 0;
    background-color: $black-color;

    .impact_card {
        border: 1px solid #6F6F6F;
        padding: 20px 30px 10px 30px;
        border-radius: 20px;
        margin-bottom: 30px;

        &.hor_card {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &.height_card {
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            @media screen and (max-width:1023px) and (min-width:768px) {
                height: auto;

            }
        }

        &.second_card {
            @media screen and (max-width:1200px) and (min-width:1024px) {
                height: 92%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            @media screen and (max-width:1023px) and (min-width:768px) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                img {
                    height: 200px;
                    width: auto;


                }
            }
        }

        @media screen and (max-width:1023px) and (min-width:768px) {
            margin-bottom: 30px;

        }

        img {
            width: 100%;
            object-fit: contain;

            &.small_img {
                height: 150px;
                width: auto;

                @media screen and (max-width:767px) {
                    height: 100px;

                }

                @media screen and (max-width:400px) {
                    height: 80px;

                }
            }

            &.small_img2 {
                height: 80px;
                width: auto;
            }
        }

        .card_para {
            // color: $yellow-color;
            font-size: 3em;
            font-weight: 700;

            @media screen and (max-width:767px) {
                font-size: 1.5em;

            }

            @media screen and (max-width:1200px) and (min-width:1024px) {
                font-size: 2em;

            }

            @media screen and (max-width:1023px) and (min-width:768px) {
                font-size: 2.5em;

            }
        }

        .card_para2 {
            color: $white-color;
        }
    }
}

.ytp-pause-overlay {
    display: none !important;
}

#achievements {
    .travel_recog {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}

.history_div {
    background-color: $black-color;
    padding: 50px 0 75px 0;
    margin-left: -4%;
    border-top: 1px solid;
    border-image: linear-gradient(90deg, #000 25%, #fff 50%, #fff 60%, #000 76%) 100% 1;

    .head_main_his {
        margin-bottom: 0px;
    }

    .history_head {
        color: $yellow-color;
        margin-bottom: 30px;
        margin-left: 5%;
        height: 160px;
        display: flex;
        font-size: 1.2em;
        align-items: flex-end;
        visibility: hidden;
        line-height: 1em;

        // align-items: flex-end;
        @media screen and (max-width:1400px) and (min-width:1201px) {
            height: 180px;

        }

        @media screen and (max-width:900px) {
            font-size: 1em;
            margin-left: -2%;
            align-items: flex-end;
        }

        @media screen and (max-width:1200px) and (min-width:901px) {
            height: 180px;
            margin-left: 4%;
        }
    }

    .history_desc {
        color: $white-color;
    }

    .slide_div_1 {
        padding: 0 3em;
    }

    .slide_div {
        padding: 20px 0;
        margin-top: 20px;
        border-top: 1px solid $white-color;
        position: relative;

    }

    .his_num {
        -webkit-text-stroke: 1px #9a9a9a;
        //   background-color: #000000; /* Background color */
        //   font-size: 6em;
        //   font-weight: 700;
        transform: rotate(270deg);
        position: absolute;
        bottom: 15%;
        left: 40px;
        //   -webkit-background-clip: text; /* Clip the background to the text */
        //   background-clip: text; /* Clip the background to the text */
        font-size: 6em;
        font-weight: 700;
        -webkit-text-stroke: 1px #9a9a9a;
        color: transparent;

        @media screen and (max-width:900px) {

            font-size: 3em;

            transform: none;
            position: inherit;

            /* left: 40px; */
            padding-left: 28px;
        }

        @media screen and (max-width:1200px) and (min-width:901px) {
            background-color: #000000;
            font-size: 3.5em;
            font-weight: 700;
            transform: rotate(270deg);
            position: absolute;
            bottom: 16%;
            left: 40px;
        }

    }

    .history_circle {
        width: 17px;
        height: 17px;
        background: $gray;
        position: absolute;
        border-radius: 50%;
        top: -3%;
        left: 10%;

        &::before {
            content: "";
            height: 367px;
            display: block;
            background: $gray;
            width: 1px;
            margin-left: 42%;
            margin-top: 2%;

        }



    }

    .his_img {
        img {
            // width: 400px;
            // height: 262px;
            width: 500px;
            height: 300px;
            margin-left: 37%;
            object-fit: cover;
            margin-top: 3%;
            border-radius: 15px;
            filter: brightness(0.3);

            @media screen and (max-width:1400px) and (min-width:1201px) {
                width: 64%;

            }

            @media screen and (max-width:900px) {
                width: 74%;
                height: 200px;
                margin-left: 68px;
                /* padding-left: 67px; */
                object-fit: cover;
                margin-top: 3%;
                border-radius: 15px;
            }

            @media screen and (max-width:1200px) and (min-width:901px) {
                width: 366px;
                height: 226px;
                margin-left: 32%;
                object-fit: cover;
                margin-top: 3%;
                border-radius: 15px;
            }
        }
    }

    .slick-list {
        padding: 0 20% 0 0 !important;
    }

    .slick-slide {
        &.slick-active.slick-current {
            .history_head {
                // display: block;
                visibility: visible;
            }

            .history_circle {
                background: #FFC601;

                /* Apply styles to .history_circle within active slides */
                &::after {
                    content: "";

                    display: block;


                    border-radius: 50%;

                    border: 1px solid $white-color;
                    padding: 10px;
                    padding: 13px;
                    position: absolute;
                    top: -5px;
                    left: -5px;


                }

                &::before {

                    background: $yellow-color;

                }
            }

            .his_num {
                color: $yellow-color;
                -webkit-text-stroke: transparent;
            }

            .his_img {
                img {

                    filter: brightness(1);
                }
            }

            // &:first-child .history_circle {
            //     background: gray; /* Apply styles to the .history_circle within the first active slide */
            // }



        }
    }

    .slick-prev {
        position: absolute;
        bottom: 9px;
        right: 90px;
        top: 28%;
        left: auto;
        bottom: -11%;
        z-index: 1;
        opacity: 1;

        &::before {
            color: $yellow-color;
            font-size: 25px;
            opacity: 1;
        }

        @media screen and (max-width: 767px) {
            top: auto;
            bottom: -60px;
            right: 50%;
        }
    }

    .slick-next {
        position: absolute;
        bottom: 9px;
        right: 50px;
        top: 28%;
        left: auto;
        z-index: 1;
        bottom: -11%;
        opacity: 1;

        &::before {
            color: $yellow-color;
            font-size: 25px;
            opacity: 1;
        }

        @media screen and (max-width: 767px) {
            top: auto;
            bottom: -60px;
            right: 40%;
        }
    }
}

.smallBanner {

    // background-color: $black-color;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 170px 0 50px 0;
    color: $white-color;
    background-position: center;

    // height: 80vh;
    @media screen and (max-width:767px) {
        font-size: 1.5em;

    }

    .banner_logo_div {
        padding-top: 20px;

        ul {
            list-style-type: none;

            li {
                display: inline-block;
                margin-left: 20px;

                a {
                    color: $white-color;
                    text-decoration: none;
                    font-size: 0.8em;
                    text-transform: uppercase;
                }
            }
        }
    }

    #banner-logo {
        width: 200px;
        margin-bottom: 30px;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .small_head {

        margin-bottom: 20px;
        font-size: 1.2em;
        position: relative;
        font-weight: 400;
        display: inline-block;

        &::after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 0px;
            width: 100%;
            height: 2px;
            background-color: $yellow-color;
        }

    }

    p {
        margin-bottom: 10px;
        // font-size: 1.4em;
        font-weight: 700;

        @media screen and (min-width: 1201px) {
            &.about_para {
                font-size: 2.5em;
                width: 70%;

            }

        }

        @media screen and (max-width: 1200px) {
            &.about_para {
                font-size: 1.4em;

            }

        }
    }

    .yellow-button {
        margin-top: 4em;
    }

    video {
        width: 70%;
        height: auto;

        @media screen and (max-width: 1023px) {
            width: 100%;
            margin-top: 10%;

        }
    }

    .transparent-button {
        color: $yellow-color;
        padding-left: 0px;
        padding-right: 0px;
        font-size: 1.3em;
    }

    .about_para_2 {
        margin-bottom: 0px;
        font-weight: 300;
    }

    .about_card_div {
        width: 100%;
        margin-top: 100px;

        .about_card {
            width: 90%;

            .about_head {
                font-size: 1.3em;

                a {
                    color: $yellow-color !important;
                    text-decoration: none;

                }
            }

            .about_para_3 {
                padding-top: 15px;
                margin-top: 15px;
                // border-top: 1px solid $yellow-color;
                font-size: 1em;
                position: relative;

                &::before {
                    content: "";
                    background-color: $white-color;
                    width: 60%;
                    height: 1px;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    &.investors {
        background-image: linear-gradient(to right, rgb(0 0 0 / 45%), rgba(0, 0, 0, 47%)), url(../img/investors/bg.jpg);
    }

    &.partner_bg {
        background-image: linear-gradient(to right, rgb(0 0 0 / 88%), rgba(0, 0, 0, 47%)), url(../img/partner/bg.png);
        justify-content: flex-start;
    }

    &.news_bg {
        background-image: linear-gradient(to right, rgb(0 0 0 / 88%), rgba(0, 0, 0, 47%)), url(../img/news/news-bg.png);
        // justify-content: flex-start;
    }

    &.ebooks_bg {
        background-image: linear-gradient(to right, rgb(0 0 0 / 88%), rgba(0, 0, 0, 47%)), url(../img/ebooks/ebook-bg.png);
        // justify-content: flex-start;
    }

    &.hotel_faq {
        background-image: linear-gradient(to right, rgb(0 0 0 / 88%), rgba(0, 0, 0, 47%)), url(../img/hotels/bgfaq.jpg);
        // justify-content: flex-start;

    }
    &.hotel_customer {
        background-image: linear-gradient(to right, rgb(0 0 0 / 88%), rgba(0, 0, 0, 47%)), url(../img/hotels/hotel-customer-main.png);
        // justify-content: flex-start;

    }

    &.career_bg {
        // background-image: linear-gradient(to right, rgb(0 0 0 / 45%), rgba(0, 0, 0, 47%)), url(../img/careers/bg.png);
        background-color: $black-color;
        padding-top: 200px;
        padding-bottom: 65px;

        a {
            margin-top: 30px;
            display: block;
        }

        @media screen and (max-width: 768px) {
            padding-top: 160px;
            // background: $black-color;
        }

        @media screen and (max-width: 1200px) {
            // padding-top: 160px;
            background: $black-color;
        }
    }

    &.whyus_bg {
        background-image: linear-gradient(to right, rgb(0 0 0 / 88%), rgba(0, 0, 0, 47%)), url(../img/partner/bg.png);
        justify-content: flex-start;

        .yellow-button {
            margin-top: 3em;
        }

        .small_head {
            margin-bottom: 30px;
        }

        .about_para_2 {
            @media screen and (max-width: 768px) {
                padding-top: 10px;

            }
        }
    }
}

.form-group.required label {
    position: relative;

    &::after {
        content: "*";
        color: #d9534f;
        margin-left: 2px;
    }
}

.investor_edge {
    padding: 75px 0;
    background-color: $beige;

    @media screen and (max-width: 768px) {
        padding-top: 50px;

    }

    &.abt_edge {
        padding-bottom: 0px;
    }

    &.partner_edge {
        padding-top: 25px;
    }

    .nav-item {
        margin-bottom: 20px;

        a {
            background-color: #F3F0EB;
            border: 1px solid #E8E5E1;
            color: $black-color;
            font-size: 1.3em;

            padding: 15px 25px;
            // background: rgb(26,26,26);
            backdrop-filter: blur(80px);
            transition: all 300ms ease-in-out;

            &.tab_link_btn {
                padding: 0px;
                background-color: transparent;
                border: none;
                color: $black-color;
                display: block;
            }

            p {
                display: none;
                font-size: 0.8em;
                font-weight: 400;
                margin-top: 15px;
                line-height: 1.4em;
                margin-bottom: 0px;
            }

            .transparent-button {
                display: none;
            }

            ul {
                display: none;
                margin-top: 20px;

                li {
                    font-size: 0.8em;
                    font-weight: 400;
                }
            }

            .res_img {
                display: none;

            }

            &[aria-selected="false"] {
                &:hover {
                    border-color: #FFC50B;
                }
            }

            &[aria-selected="true"] {
                background-color: $beige;
                border-color: #FFC50B;
                color: $black-color;
                font-weight: 800;



                // background: rgb(26,26,26, 0.6);
                backdrop-filter: blur(80px);

                p {
                    display: block;
                }

                .transparent-button {
                    display: flex;
                    transition: all 0.4s ease;
                    background-color: black;
                    color: $white-color;

                    &:hover {
                        background-color: $white-color;
                        color: $black-color;
                    }
                }

                ul {
                    display: block;
                }

                .res_img {
                    @media screen and (max-width: 1200px) {
                        display: block;
                    }

                }

                // animation start


                // animation ed
            }
        }

    }

    .investor_edge_div {
        background: linear-gradient(30deg, #FFF277 25%, #ffe75f 50%, #fce372 60%, #F5C266 76%) 100% 1%;
        padding-left: 2em;
        padding-top: 2em;
        border-radius: 10px;

        img {
            width: 100%;

            border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
            // border-top-left-radius: 15px;

        }

        &.leadr_img {

            img {
                height: 400px;
                background: $black-color;
                object-fit: contain;
            }
        }
    }

    .res_img {
        margin-top: 20px;
        padding-left: 1em;
        padding-top: 1em;

        img {
            // padding-left: 1em;
            // padding-top: 1em;
            width: 100%;
        }
    }

    .second_div {
        @media screen and (max-width: 1200px) {
            display: none;
            ;
        }
    }
}

.investor_details {
    padding: 15px 0 50px 0;
    background-color: $beige;

    .card {
        background-color: #F3F0EB;
        padding: 30px;
        border: none;
        border-radius: 15px;

        .row {
            align-items: center;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 15px;
        }

        .investor_name {
            font-weight: 700;
            margin-bottom: 0px;
            font-size: 1.3em;

            @media screen and (max-width: 767px) {

                margin-top: 20px;

            }
        }

        .investor_company {
            font-size: 0.9em;
            margin-bottom: 0px;

            &::after {
                content: "";
                width: 80px;
                height: 1px;
                background-color: $orange-color;
                display: block;
                margin-top: 10px;
            }
        }

        .investor_testimonial {
            margin-top: 15px;
            font-size: 1.2em;
            line-height: 1em;
        }
    }
}

.investor-result {
    padding: 35px 0 50px 0;

    @media screen and (max-width: 768px) {
        padding: 25px 0;
    }

    background-color: $beige;

    .num_border {
        @media screen and (min-width: 1200px) {

            padding-right: 20px;

        }

        .num_div {
            display: flex;

            align-items: flex-end;
            border-left: 2px solid $orange-color;
            padding-top: 15px;

            @media screen and (max-width: 1200px) and (min-width: 768px) {

                padding-right: 20px;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .number {
            font-size: 4em;
            margin-bottom: 0px;
            padding-bottom: 0px;
            line-height: 1em;
            padding-left: 20px;

            @media screen and (max-width: 900px) {
                font-size: 2em;
                padding-left: 10px;
            }
        }

        .num_desc {
            font-size: 1em;
            margin-bottom: 0px;
            padding-bottom: 0px;
            padding-left: 20px;

            @media screen and (max-width: 768px) {
                padding-left: 15px;
            }
        }

        .num_desc_2 {
            padding-left: 20px;
            padding-top: 30px;

            @media screen and (max-width: 1200px) and (min-width: 768px) {
                padding-top: 10px;
            }

            @media screen and (max-width: 768px) {
                padding-left: 0px;
                padding-top: 10px;
            }
        }
    }

    &.home-result {
        .inv_res_2 {
            @media screen and (min-width: 1200px) {
                margin-top: 40px;
            }
        }

        .num_border {
            .number {
                @media screen and (max-width: 1200px) and (min-width: 768px) {
                    font-size: 2em;
                }
            }
        }

        &.why_result {
            padding: 50px 0;
        }
    }
}

.sciative_media {
    border-top: 1px solid;
    border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;
    padding: 50px 0;
    background-color: $beige;

    .media_head {
        span {
            background-color: #F3EFEB;
            padding: 5px 10px;
            border-radius: 5px;
            margin-right: 7px;
        }
    }

    .article_head {
        font-weight: 600;
        margin-bottom: 15px;

        a {
            color: $black-color;
            text-decoration: none;
        }
    }

    .media_row {
        border-bottom: 1px solid $black-color;
        padding-bottom: 20px;
        margin-bottom: 20px;

        img {
            width: 100%;

            @media screen and (max-width: 768px) {
                width: 60%;
            }
        }

    }

    .black-button {
        color: $yellow-color;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        span {
            padding-left: 10px;
        }

        &:hover {
            color: $white-color;
        }

    }
}

.partner_value {
    background-color: $beige;
    padding: 30px 0 50px 0;

    .card_div {
        margin-bottom: 30px;
    }

    .row {
        justify-content: center;
    }

    .card {
        border: none;
        box-shadow: 1px 1px 3px $gray;
        background-color: $beige;
        align-items: center;
        padding: 30px 20px;
        height: 100%;

        img {
            height: 80px;
            width: 130px;
            object-fit: contain;
            object-position: top;
        }

        p {
            text-align: center;
        }
    }
}

.why_partner {
    padding: 50px 0;
    background-color: $beige;

    .why_row {
        margin-bottom: 30px;
    }

    .card_2 {
        background-color: #F3F0EB;
        border: 1px solid #E8E5E1;
        border-radius: 15px;
        height: 100%;
        margin: 0px;

        // padding: 30px;
        .why_headings {
            padding: 20px;

            .why_head {
                font-size: 1.5em;
                font-weight: 600;
            }
        }

        .why_img {
            background-color: #F9F8F5;
            border-top-left-radius: 10px;
            border-radius: 10px;
            padding: 30px 50px 30px 40px;

            margin-top: 55px;

            @media screen and (max-width: 768px) {
                // display: flex;
                // justify-content: flex-end;
                padding: 20px;
                margin-top: 0px;
            }

            @media screen and (max-width: 1200px) and (min-width: 1024px) {
                margin-top: 5px;
            }

            @media screen and (max-width: 1400px) and (min-width: 1201px) {
                margin-top: 115px;
            }

            .img_inside {
                background-color: #FBEBB5;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border: 1px solid #FFC601;
                border-radius: 5px;

                @media screen and (max-width: 768px) {
                    // display: inline-flex;
                }

                img {
                    width: 80px;
                    height: 64px;
                    object-fit: contain;
                }
            }
        }
    }
}

.partner-form-section {
    padding: 50px 0;
    background-color: #FEFBF5;

    .text-container {
        text-align: center;
    }

    .form-container {
        width: max(22rem, 47vw);
        margin: auto;
    }
}

.investor-form-section {
    background-color: #FEFBF5;
    padding: 25px 0 50px 0;

    .text-container {
        text-align: center;
    }

    .form-container {
        width: max(22rem, 47vw);
        margin: auto;
    }
}

.career_img {
    padding: 50px 0 10px 0;
    background-color: $beige;
    border-top: 1px solid;
    border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;
    margin-top: 75px;

    @media screen and (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    p {
        font-size: 1.2em;

        &:nth-last-child(1) {
            margin: 0px;
        }
    }

    img {
        width: 100%;
        margin-bottom: 15px;

        &.c_gal_1 {
            width: 80%;
            text-align: right;
        }
    }

    .cgal2 {
        @media screen and (min-width: 768px) {
            margin-top: 10px;
        }
    }

    .c_gal {

        @media screen and (min-width: 768px) {
            padding-right: 0px;


        }

        @media screen and (min-width: 768px) {
            // display: inline-flex;
            text-align: end;


        }

        @media screen and (max-width: 767px) {
            margin-top: 30px;
            padding: 0px;

            .c_gal_1 {
                width: 100%;
            }
        }

        @media screen and (max-width: 1200px) and (min-width: 768px) {

            .c_gal_1 {
                width: 100%;
            }

        }

    }

    @media screen and (max-width: 1200px) and (min-width: 768px) {


        .div_car_2 {
            margin-top: 30px;
        }
    }

    .bigHead {
        margin-bottom: 15px;
    }

    .div_car_1 {
        padding-left: 0px;

        @media screen and (min-width: 1200px) {
            margin-left: 5%;
        }

    }

    .div_car {
        align-items: center;
    }
}

.careers-form-modal {
    max-width: max(22rem, 70vw) !important;

    @media screen and (width <=578px) {
        margin: auto !important;
    }

    .modal-body {
        background-color: #FEFBF5;
        height: 90vh;
        overflow-y: auto;

        input,
        textarea {
            background-color: #FEFBF5 !important;

            &:focus {
                border-color: rgba(184, 124, 4);
                box-shadow: 0 0 0 0.25rem rgba(184, 124, 4, .25);
            }
        }
    }
}

.openings {
    padding: 50px 0;
    background-color: $beige;

    .accordion {
        .accordion-item {
            padding: 5px;
            background-color: $beige;
            margin-top: 20px;
            border: none;
            box-shadow: none !important;
        }

        .accordion-header {
            background-color: $beige;
            border-radius: 10px;

            @media screen and (max-width: 1200px) and (min-width: 1024px) {
                margin-top: 1%;

            }

            .accordion-button {
                border: 1px solid #FFC50B;

                border-top-left-radius: 10px;
                border-top-left-radius: 10px;
                background-color: $beige;
                box-shadow: none;
                font-weight: 500;
                font-size: 0.7em;
                color: $black-color;
                border-bottom-width: 0px;

                &[aria-expanded="false"] {
                    background-color: #F3F0EB;
                    border-color: #F3F0EB;
                    border-radius: 10px !important;
                    border-width: 1px;
                }
            }
        }

        button {
            font-size: 0.8em;
            width: 100%;
        }

        .accordion-body {
            border: 1px solid #FFC50B;
            border-radius: 10px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-top-color: $beige;

            padding-top: 0px;

            p {
                font-weight: 400;

                @media screen and (max-width: 767px) {
                    font-size: 1em;
                }
            }

            li {
                @media screen and (max-width: 767px) {
                    font-size: 1em;
                }
            }

            .job_subhead {
                font-size: 1.2em;
            }

            p {
                font-weight: 400;
            }

            .transparent-button {
                color: $black-color;
                display: flex;
                align-items: center;
                padding-left: 0px;
                padding-right: 0px;
                border-bottom: 1px solid $beige;

                span {
                    margin-left: 10px;
                }

                &:hover {
                    border-bottom: 1px solid $black-color;
                }
            }

            .btn_div {
                text-align: center;

                // margin-top: 15px;
                @media screen and (max-width: 767px) {
                    text-align: left;
                    margin-top: 0px;
                }
            }

            .white-button {
                @media screen and (max-width: 1400px) and (min-width: 1200px) {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }
}

.contact_page {
    // .contact_section_1 {
    //     height: 100dvh;
    // }

    .table-container {
        width: 80%;
    }

    background-color: $beige;

    h1 {
        // font-size: max(3.7vw, 1.8rem);
        font-weight: 500;
    }

    .logo_img_div {
        padding-block: max(1.3rem, 2.2vw);

        img {
            width: max(8rem, 10.5vw);
        }
    }

    .text-paragraph {
        line-height: 1.8rem;
        font-weight: 550;
        font-size: 1.3em;
        width: 80%;
    }

    .contact_img_slider {
        margin-top: 50px;

        img {
            width: 100%;
            height: 60px;
            object-fit: contain;
            transform: scale(0.7);
        }
    }

    .recog_div {
        background-color: $beige;
    }

    .black-bg {
        background-color: $beige;
    }

    .custom-phoneInput {
        input {
            padding: 1.5vw;
        }
    }

    .lm-icon {
        height: 2em;
    }

    .vertical-line {
        content: '';
        width: 1.2px;
        background-color: #000;
    }

    a {
        color: #000;
    }

    a:hover {
        color: #000;
        text-decoration: underline !important;
    }

    .expand-row {
        border-radius: 200px;
        height: 10px;
        width: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1.2px solid #000;
        transition: all 0.1s ease-in;
    }

    table {
        font-size: 0.8em;
        table-layout: fixed;
        border-collapse: collapse;
        transition: all 0.4s ease-in;

        thead {
            transition: all 0.4s ease-in;

            tr {
                background: #f3f0eb;
                height: 3em;
                color: #4e4b5c;
                transition: all 0.4s ease-in;
            }

            td {
                transition: all 0.4s ease-in;
            }
        }

        tbody {
            transition: all 0.4s ease-in;

            .count-text {
                color: #4e4b5c;
                // cursor: pointer;
            }

            tr {
                height: 4.2em;
                background-color: #fff;
                border-bottom: 1.4px solid #d2d2d2;
                transition: all 0.4s ease-in;
            }

            td {
                // vertical-align: text-top;
                transition: all 0.4s ease-in;
            }

            .topics-section {
                transition: all 0.4s ease-in;
            }

            .topic-pill {
                border-radius: 200px;
                background-color: #f3f0eb;
                color: #000;
                text-align: center;
                font-size: 0.7em;
                width: 100px !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis !important;
                display: block;
            }

            .skill-level {
                font-size: 1.2em;
            }

            .topic-tile {
                border-radius: 8px;
                min-width: 100px;
                max-width: auto;
                background-color: transparent;
                border: 1.4px solid #f3f0eb;
                transition: all 0.1s ease-in;
                cursor: pointer;
            }

            .topic-tile:hover {
                background-color: #f3f0eb;
            }

            .ellipsis {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis !important;
            }
        }
    }

    @media screen and (width <=1024px) {
        .contact_section_1 {
            height: auto;
        }

        .table-container {
            width: 80%;
        }

        .skill-level {
            font-size: 1em;
        }
    }

    @media screen and (width <=768px) {
        .topic-tile {
            font-size: 0.7em;
            min-width: 5em !important;
            width: auto !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis !important;
        }

        .skill-level {
            font-size: 0.8em;
        }

        .table-container {
            width: 100%;
        }

        .container {
            margin-inline: 0;
            max-width: 100%;

            .logo_img_div {
                text-align: center;
            }

            .row-container {
                gap: 1rem;

                .text-container {
                    width: 100%;
                    text-align: center;

                    h1:first-of-type {
                        margin: 0;
                    }

                    p {
                        width: 100%;
                        line-height: 1rem;
                    }
                }

                .form-container {
                    width: 100%;
                }

            }

        }

        .info_contact {
            .row-media-container {
                gap: 1rem;
            }
        }
    }
    &.contact_page_lp{
        h1{
            margin-bottom: 20px !important;
            font-size: 3rem;
            @media screen and (max-width: 1023px) {
                font-size: 2rem;
            }
        }
        h2{
            font-size: 1.8rem;
            span{
                font-size: 1.5rem;
            }
        }
        .text-paragraph{
            font-size: 1.3em;
            line-height: normal;
            font-weight: 400;
            @media screen and (max-width: 768px) {
             
                   width: 100%;
                   line-height: 1.3rem !important;
               
           }
        }
        .row_img img{
            width: 100px;
            margin-left: -20%;
            @media screen and (max-width: 768px) {
                margin-left: 0;
            
            }
        }
        .row_img img {
            -webkit-animation: mover 1s infinite  alternate;
            animation: mover 1s infinite  alternate;
        }
        .row_img img {
            -webkit-animation: mover 1s infinite  alternate;
            animation: mover 1s infinite  alternate;
        }
        .row_img{
            @media screen and (max-width: 768px) {
             display: none;
            
            }
        }
        @-webkit-keyframes mover {
            0% { transform: translateY(0); }
            100% { transform: translateY(-30px); }
        }
        @keyframes mover {
            0% { transform: translateY(0); }
            100% { transform: translateY(-30px); }
        }
       
        .lp_table {
            padding: 50px 0 75px 0;
            margin-top: 20px;
            // border-top: 1px solid;
            // border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;
           .info_2{
            border-top: 1px solid;
            border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;
           }
            .card {
                background-color: #F3F0EB;
                border: 1px solid #E8E5E1;
                padding: 40px 20px;
                display: flex;
                justify-content: center;
                align-items: center;
        
                p {
                    margin-bottom: 0px;
        
                    &.c_info {
                        font-size: 0.8em;
                        margin-bottom: 2px;
                    }
                }
            }
            table{
                width: 100%;
            }
            thead{
                background-color: $black-color;
                color: $yellow-color;
                text-transform: uppercase;
                th{
                    padding: 30px 15px;
                    font-size: 17px;
                    span{
                        color: #BFBFBF;
                        font-size: 11px;
                        @media screen and (max-width: 1200px)and (min-width: 1024px){
                            display: block;
                        }
                    }
                }
                
            }
            td, th{
                border: 1px solid #E6E9F5;
                font-weight: 500;
                padding: 15px;
            }
            th{
                font-weight: 700;
            }
            th:nth-child(1){
                width: 33%;
            }
            th:last-child{
                width: 20%;
                text-align: center;
            }
            tr{
                background-color: transparent;
            }
            .accordion-item{
                border: none;
                box-shadow: none !important;
                background: transparent;
            }
            .accordion-body{
                padding-top: 0px;
                padding-bottom: 0px;
            }
            .accordion-button{
               
                .head1{
                    width: 70%;
                    padding-left: 5px;
                }
                .head2{
                    width: 30%;
                    text-align: right;
                }
                &::after{
                    width: 0;
                    height: 0;
                }
                &::before{
                    font-family: "FontAwesome";
                    content: "\f0d7"; 
                  
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    left: 5px;
                    margin-top: -10px;
                    // background-color: #000;
                }
                &[aria-expanded=false]{
                    background-color: #fefbf5;
                    border: none;
                    box-shadow: none;
                    color: $black-color;
                }
                &[aria-expanded=true]{
                    background-color: #fefbf5;
                    border: none;
                    box-shadow: none;
                    color: $black-color;
                }
                &.collapsed{
                    &::before{
                        content: "\f0da"; 
                       
                   
                        // background-color: #000;
                    }
                }
            }
            .accordion-body{
                p{
                    padding-left: 0%;
                }
            }
        }
        .mob-lp-head{
            background-color: $black-color;
            color: $yellow-color;
            font-size: 17px;
            padding: 10px 5px;
            text-align: center;
            border-radius: 5px;
            span{
                color: #BFBFBF;
                font-size: 12px;
            }
        }
        .mob-lp-head2{
            font-size: 14px;
        }
    }
} 

.info_contact {
    padding: 50px 0 75px 0;
    margin-top: 20px;
    border-top: 1px solid;
    border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

    .card {
        background-color: #F3F0EB;
        border: 1px solid #E8E5E1;
        padding: 40px 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            margin-bottom: 0px;

            &.c_info {
                font-size: 0.8em;
                margin-bottom: 2px;
            }
        }
    }
}
.hotel_client_all{
    .bigHead{
        font-size: 1.7em;
    }
    &.hotel_why_us {
        border-top: 1px solid;
        padding-bottom: 0px !important;
        border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

        .home_img_slider {
            justify-content: center;

            .col-4 {
                text-align: center;
            }
        }
    }
}

.all_client_home {
    padding: 50px 0;
    background-color: $beige;

    .bigHead {
        margin-bottom: 30px;
        font-size: 1.2em;
        font-weight: normal;
    }

    .home_img_slider {
        img {
            width: 100px;
            height: 70px;
            object-fit: contain;
            // margin-bottom: 30px;
            transform: scale(0.9);

            @media screen and (max-width: 350px) {
                width: 60px;
            }
        }
    }

    &.client_why_us {
        border-top: 1px solid;
        padding-bottom: 0px !important;
        border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

        .home_img_slider {
            justify-content: center;

            .col-4 {
                text-align: center;
            }
        }
    }
}

.home_product {
    padding: 50px 0;
    border-top: 1px solid;
    border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;
    background-color: $beige;

    .card {
        border-radius: 10px;

        @media screen and (max-width: 786px) {

            margin-bottom: 30px;
        }

        .bg_card {
            height: 250px;
            display: flex;
            align-items: flex-end;
            padding: 30px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            // @media screen and (max-width: 786px){
            //     height: 150px;

            // }
            &.bg_card_retail {

                background-image: url(../img/home/retail-img.jpg);

                &:hover {
                    background-image: linear-gradient(rgb(0 0 0 / 65%), rgb(0 0 0 / 83%)), url(../img/home/retail.png);
                }

                @media screen and (max-width: 1200px) {
                    background-image: linear-gradient(rgb(0 0 0 / 65%), rgb(0 0 0 / 83%)), url(../img/home/retail.png);
                }
            }

            &.bg_card_travel {

                background-image: url(../img/home/travel.png);

                &:hover {
                    background-image: linear-gradient(rgb(0 0 0 / 65%), rgb(0 0 0 / 83%)), url(../img/home/travel.png);
                }

                @media screen and (max-width: 1200px) {
                    background-image: linear-gradient(rgb(0 0 0 / 65%), rgb(0 0 0 / 83%)), url(../img/home/travel.png);
                }

            }

            p {
                color: $white-color;
                margin-bottom: 0px;
                transition: all 0.5s ease;

                @media screen and (min-width: 1200px) {
                    opacity: 0;

                }

            }

            &:hover {
                P {

                    opacity: 1;
                }
            }
        }

        .info_area {
            background-color: #F3F0EB;
            padding: 10px 30px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border-radius: #E8E5E1;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .image-text-container span {
                font-size: 0.8em;
            }

            @media screen and (max-width: 10230px) and (min-width: 800px) {
                padding: 10px;

            }

            @media screen and (width <=1200px) {
                justify-content: center;
                row-gap: 10px;

                .image-text-container {
                    width: 100%;
                    margin: auto;
                    justify-content: center;
                    gap: 3rem !important;
                }
            }

            @media screen and (width <=480px) {
                padding-inline: 10px;

                .image-text-container span {
                    // font-size: 0.9rem;
                    font-weight: 500 !important;
                    line-height: 1.01rem;
                }
            }


            img {
                width: 100px;
                height: 45px;
                object-fit: contain;
            }

            a {
                padding: 7px 20px;
                color: $black-color;
                border: 1px solid $orange-color;
                border-radius: 30px;
                text-decoration: none;
                font-weight: 600;
                transition: all 0.5s ease;
                display: flex;
                align-items: center;
                font-size: 14px;

                &:hover {
                    background-color: $orange-color;
                }
            }

            &.travel_area {
                img {
                    width: 145px;
                }
            }

        }
    }
}

.home_captera {
    background-color: $beige;

    .recog_div {
        background-color: $beige;
    }
}

.founder_video_home {

    &.black-bg,
    .founderVideo {
        background-color: $beige;
    }
}

.case_study {
    padding: 50px 0;
    background-color: $beige;

    .bigHead {
        margin-bottom: 30px;
        text-align: center;
    }

    .card {
        margin-bottom: 30px;

        .card_bg {
            height: 350px;
            border-radius: 10px;
            border-color: transparent;
            background-size: cover;

            p {
                color: $white-color;
                margin-bottom: 0px;
            }

            span {
                color: $white-color;
                font-size: 15px;
                margin-right: 10px;
            }

            .bigHead {
                text-align: left;
                font-weight: 500;
                margin-bottom: 10px;

                @media screen and (max-width: 768px) {
                    font-size: 1.7em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 95.5%;
                    display: block;
                }

            }

            .card_date {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 30px;
                height: 100%;
            }

            .case_det {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            .category {
                border-left: 1px solid $white-color;
                padding-left: 10px;
            }
        }
    }

    .cs_2 {
        margin-bottom: 30px;

        .card {
            background-color: #F3F0EB;
            padding: 20px;
            height: 100%;

            a {
                font-size: 1.2em;
                font-weight: 600;
                // @media screen and (max-width: 768px)  {
                //     -webkit-line-clamp: 2; /* number of lines to show */
                //     line-clamp: 2;
                // }
            }

            a {
                text-decoration: none;
            }

            .card_cat {
                padding: 20px 0;

                a {
                    color: $black-color;
                    background-color: $white-color;
                    font-size: 0.8em;
                    font-weight: 600;
                    padding: 5px;
                    border-radius: 3px;
                }
            }

            a {
                color: $black-color;
            }

            .read,
            .date {
                font-size: 0.8em;
                color: $black-color;
            }
        }
    }

    .yellow-button {
        display: flex;
        align-items: center;

        span {
            margin-left: 5px;
        }

        &:focus-visible,
        &:active {
            background-color: transparent !important;
            box-shadow: none !important;
            color: black;
        }
    }

    .cs_3 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.home_people {
    background-color: $beige;
    padding: 0px;

    .img_people {
        @media screen and (max-width: 767px) {
            height: 90px;

        }
    }

    img {
        @media screen and (max-width: 767px) {
            height: 90px;

        }
    }

}

.explore_sci {
    padding: 50px 0;
    background-color: $beige;

    .container {
        // display: flex;
        background-color: #F3F0EB;
        padding: 30px 20px;

        .bigHead {
            margin-bottom: 0px;
        }

        .row {
            align-items: center;
        }
    }

    &.why_sci {
        padding: 15px 40px;
        margin: 20px 0;
        border: 1px solid #E8E5E1;
        border-radius: 15px;

        .bigHead {
            font-size: 1.2em;
            margin-bottom: 0px;
            text-align: left;

            @media screen and (max-width: 767px) {
                text-align: center;
                margin-bottom: 10px;
            }
        }

        .yellow-button {
            font-weight: 600;
            font-size: 1.2em;
            padding: 5px 40px 7px 40px;
        }
    }
}

.integration_home {
    padding: 50px 0;
    background-color: $beige;
    border-top: 1px solid;
    border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

    .int_1 {
        .card {
            width: 200px;
            height: 120px;
            object-fit: contain;
            padding: 20px;
            display: flex;
            align-items: center;
            background-color: $beige;
            border: none;

            img {
                width: 70%;
                height: 80px;
                object-fit: contain;
            }
        }
    }

    .int_2 {
        justify-content: center;

        .card {
            // width: 240px;
            // height: 120px;
            object-fit: contain;
            padding: 20px;
            display: flex;
            align-items: center;
            background-color: $beige;
            border: none;


            img {
                width: 70%;
                height: 80px;
                object-fit: contain;

                @media screen and (max-width: 768px) {
                    object-fit: contain;
                    height: 30px;
                    width: auto;
                }
            }

        }
    }

    .card {
        @media screen and (max-width: 1200px) and (min-width: 768px) {
            padding: 0px !important;

        }
    }
}

.client_det {
    background-color: $beige;
    padding: 50px 0;
    border-top: 1px solid;
    border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

    .bigHead {
        text-align: center;
    }

    .card-wrapper {
        padding-inline: 0.8rem;
    }

    .card {
        padding: 20px;
        background-color: #F3F0EB;
        border-radius: 15px;
        border-color: #E8E5E1;

        .flex_card {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            @media screen and (width <=530px) {
                flex-direction: column;
                align-items: center;
                row-gap: 1rem;
            }

            .card_1 {
                display: flex;
                align-items: center;

                @media screen and (max-width: 360px) {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                }

                .client_desc {
                    padding-left: 20px;

                    @media screen and (max-width: 768px) {
                        padding-right: 5px;

                    }

                    @media screen and (max-width: 360px) {
                        padding-left: 0px;
                        padding-top: 20px;
                    }

                    p {
                        margin-bottom: 0px;

                        &.namec {
                            font-weight: 600;
                        }

                        &.named {
                            font-size: 0.8em;
                        }
                    }
                }

                img {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                    border-radius: 50%;

                    @media screen and (max-width: 420px) {
                        width: 60px;
                        height: 60px;
                    }
                }


            }

            .kk_img {
                width: auto;
                height: 2em;
                margin-bottom: 30px;
            }
        }

        .client_test {
            margin-top: 30px;

            @media screen and (width <=530px) {
                margin-top: 0px;
            }
        }
    }

    .client_det_para {
        p {
            margin-top: 30px;
            margin-bottom: 20px;
            font-weight: 700;
        }

        .yellow-button {
            padding: 10px 20px;
        }
    }

    &.auto_price {
        padding: 75px 0;
    }

    &.inside_det {
        border-image: none;
        border-top: none;
        border-bottom: 1px solid;
        border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

        .client_desc {
            background-color: #FEFBF5;
            border: 1px solid #E8E5E1;
            padding: 10px;
            border-radius: 10px;
        }
    }
}

.form-section {
    padding: 50px 0;
    background-color: #FEFBF5;
    border-top: 1px solid;
    border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

    .text-container {
        text-align: center;

        h2 {
            margin-bottom: 1rem !important;
        }
    }

    .form-container {
        width: max(22rem, 47vw);
        margin: auto;
    }
}

.card_why_us {
    padding: 50px 0 50px 0;
    background-color: $beige;
    border-top: 1px solid;
    border-image: linear-gradient(90deg, #fff 25%, #000 50%, #000 60%, #fff 76%) 100% 1;

    .about_why {
        padding: 20px 0;
    }

    .col-md-4 {
        @media screen and (max-width: 768px) {
            margin-bottom: 30px;
        }
    }

    .card {
        @media screen and (max-width: 1023px) and (min-width: 768px) {
            margin: 0px
        }
    }
}

.retail_bg_cta {
    background-color: $beige;

    .card {
        background-size: cover;
        background-position: center;
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 30px;
        border-radius: 15px;
        border: none;

        @media screen and (max-width: 767px) {
            padding: 20px;
        }

        .card_text {
            color: $white-color;
            font-size: 1.7em;

            @media screen and (max-width: 768px) {
                text-align: conter;
            }
        }

        .yellow-button {
            font-size: 1.4em;
            font-weight: 500;
        }

    }

    .ap_card {
        background-image: url(../img/retail/bg_cta.png);

        &.game_card {
            background-image: url(../img/retail/game.jpg);
        }
    }

    &.comp_cta {
        .ap_card {
            justify-content: center;
            height: 370px;
        }

        .card_text {
            font-size: 1.4em;

            @media screen and (max-width: 767px) {
                font-size: 1.2em;
                text-align: center;
            }
        }

        .yellow-button {
            @media screen and (max-width: 767px) {
                font-size: 1em;
            }
        }

        .comp_card {
            display: flex;
            justify-content: center;
            background-color: $yellow-color;
            padding: 15px 25px;
            align-items: center;
            border-radius: 15px;
            margin-bottom: 40px;

            @media screen and (max-width: 767px) {
                font-size: 0.5em;
                padding: 5px;
                width: 100% !important;
            }

            span {
                color: $black-color;
                margin-right: 20px;
                font-size: 1.2em;

                &.stext {
                    font-weight: 700;
                    margin-right: 20px;

                    @media screen and (max-width: 1200px) and (min-width: 768px) {
                        margin-right: 9px;
                    }
                }

                &.snum {
                    background-color: $white-color;
                    color: $black-color;
                    padding: 2px 15px;
                    margin-right: 20px;
                    font-weight: 700;
                }
            }
        }
    }
}

.market_int_product {
    background-color: $beige;
    padding: 25px 0 50px 0;

    &.ret_page {
        padding: 50px 0;
    }

    .row_flex {
        display: flex;
        justify-content: center;

        .img_div {
            margin: 0 50px;

            @media screen and (max-width: 900px) {
                margin: 0px;
            }
        }

        img {
            width: 200px;
            height: 80px;
            object-fit: contain;
            padding: 20px;

            @media screen and (max-width: 767px) {
                width: 125px;
                height: 80px;
                padding: 13px;
            }

            @media screen and (max-width: 330px) {
                width: 105px;
            }
        }
    }

    p {
        margin-top: 30px;
    }
}

.card_retail_product {
    padding: 50px 0;
    background-color: $beige;

    .about_why {
        padding: 0px;



        img {
            width: 60px;

            height: 60px;
        }
    }

    .abt_why2 {
        @media screen and (max-width: 767px) {
            margin-bottom: 30px;
        }
    }
}

.qa_retail {
    padding: 50px 0;
    background-color: $beige;

    .qa_quest {
        .card {
            flex-direction: column;
            row-gap: 1rem;
            justify-content: space-between;
            padding: 20px;
            background-color: #F3F0EB;
            border-color: #E8E5E1;
            border-radius: 15px;
            margin-bottom: 20px;

            .card-upper {
                display: flex;
                justify-content: space-between;

                @media screen and (width <=480px) {
                    flex-direction: column;
                    row-gap: 1rem;
                    align-items: center;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 1.2em;
                }

                .icons {
                    display: flex;

                    button {
                        width: 40px;
                        height: 40px;
                        display: inline-block;
                        border-radius: 50%;
                        // border: 1px solid $yellow-color;
                        // background-color: $yellow-color;
                        // color: $black-color;
                        color: white;
                        margin-left: 20px;
                        display: inline-flex;
                        align-items: center;
                        align-content: center;
                        justify-content: center;
                        text-decoration: none;
                        transition: 0.2s ease-in-out;

                        span {
                            font-size: 18px;
                        }
                    }

                    .positive-bg {
                        background-color: grey;

                        &:hover {
                            background-color: #24A7A5;
                            outline: 1px solid #24A7A5;
                            outline-offset: 5px;
                        }
                    }

                    .negative-bg {
                        background-color: grey;

                        &:hover {
                            background-color: #DF6049;
                            outline: 1px solid #DF6049;
                            outline-offset: 5px;
                        }
                    }

                    .negative-active-btn {
                        background-color: #DF6049;
                        outline: 1px solid #DF6049;
                        outline-offset: 5px;
                    }

                    .positive-active-btn {
                        background-color: #24A7A5;
                        outline: 1px solid #24A7A5;
                        outline-offset: 5px;
                    }
                }
            }

            .card-lower {
                align-self: flex-start;
                border: 1px solid #BBBBBB;
                border-radius: 7px;
                padding: 0.3rem 0.8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 1rem;

                span {
                    font-size: 1.2em;
                    font-weight: 600;
                }

                span.active {
                    background-color: $yellow-color;
                    border-radius: 21px;
                    padding: 0.4rem 1.1rem;
                }

                @media screen and (width <=480px) {
                    align-self: center;
                }
            }

        }
    }
}

.qa_store {
    background-color: $beige;
    padding: 50px 0;

    a {
        text-decoration: none;

        @media screen and (max-width: 767px) {
            margin-bottom: 30px;
            display: block;
        }

    }

    .card {
        background-color: #F3F0EB;
        border-color: #E8E5E1;
        border-radius: 15px;
        padding: 20px;
        width: 100%;
        height: 265px;
        overflow: hidden;

        .smallHead {
            font-weight: 700;
            text-decoration: none;
        }

        img {
            width: 141%;
            height: 371px;
            margin: 25px 0 0 31px;
            transition: all 0.5s ease;
        }

        &:hover {
            border-color: $yellow-color;

            // box-shadow: rgba(100, 100, 111, 0.2) 0px 6px 10px 0px;
            // transform: translateY(-4px);
            img {
                transform: scale(1.1);
                // border-top-left-radius: 20px;
                // border:1px solid $yellow-color;
            }
        }
    }

    &.role_store {
        .bigHead {
            margin-bottom: 15px !important;
        }
    }
}

.main_banner {
    #banner-logo {
        width: 110px;

        &.travel-logo {
            width: 140px;
        }
    }

    .small_head {

        margin-bottom: 20px;
        font-size: 1.2em;
        position: relative;
        font-weight: 400;
        display: inline-block;

        &::after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 0px;
            width: 100%;
            height: 2px;
            background-color: $yellow-color;
        }

    }

    .transparent-button {
        display: flex;
        align-items: center;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;
        color: $yellow-color;

        span {
            padding-left: 5px;
        }

        &:hover {
            border-color: $yellow-color;
        }
    }

    .yellow-button {
        color: $black-color !important;
        padding: 5px 10px;


    }

    .mega-box {
        position: absolute;
        left: 0;
        width: 100%;
        padding: 0 30px;
        top: 85px;
        opacity: 0;
        visibility: hidden;
        z-index: 1;
        scale: 0;
    }

    .mega-box .content {
        background: #fff;
        border: 1px solid #F3F3F4;
        border-radius: 15px;
        padding: 50px 20px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
        color: $black-color;

        a {
            color: $black-color;
            text-align: left;
            transition: all 1s ease;

            &:hover {
                color: $black-color;

                .nav_head {
                    text-decoration: underline;

                    &.active {
                        text-decoration: underline;
                    }
                }
            }
        }

        .has_menu_2 {
            width: 100% !important;

        }

        .nav_head {
            font-size: 1.1em;
            font-weight: 700;
            text-align: left;

            &.nav_head_2 {
                color: $gray;
                font-size: 1em;
            }
        }

        p {
            text-transform: initial;

            @media screen and (min-width: 1200px) {
                &.first_p {

                    width: 70%;
                }


            }

            @media screen and (min-width: 1400px) {
                &.first_p {

                    width: 55%;
                }


            }
        }
    }

    .mega-box .content .row {
        width: calc(25% - 30px);
        line-height: 45px;
    }

    .banner_logo_div {
        position: relative;
    }

    .banner_logo_div li:hover .mega-box {
        transition: all 0.3s ease;
        scale: 1;
        opacity: 1;
        visibility: visible;
        top: 38px;
        padding: 25px 0;
    }

    .content_2 {

        p {
            margin-bottom: 0px;
            line-height: 30px;
        }

        a {
            display: block;

            &:hover {
                p {

                    text-decoration: underline;
                }
            }
        }
    }

}

.news-card {

    border-radius: 15px;
    margin-bottom: 30px !important;
    //    this was causing conflict  // height: 18em;

    .img_card_div {
        height: 200px;
        display: grid;
        align-items: center;
        justify-content: center;
        border: 1px solid #E8E5E1;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        img {
            margin-bottom: 0px;
            width: 200px;
            height: 200px;
            object-fit: contain;
        }
    }

    .news-card-title-section {
        background-color: #F3F0EB;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        @media screen and (min-width: 1200px) {
            height: 160px;
        }

        a {
            font-size: 1.3em;
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* number of lines to show */
            line-clamp: 3;
            -webkit-box-orient: vertical;

            // height: 80px;
        }
    }

    .news-card-date {
        margin-top: 30px;
        font-size: 0.9em;
        color: #9A9A9A;
    }

}

.news_bg {
    h1 {
        font-weight: 800;
    }

    .subscribe-btn {
        font-weight: 700;

        @media screen and (max-width: 768px) {
            font-size: 0.7em;
        }
    }

    .input-box {
        padding: 5px 20px !important;

        @media screen and (max-width: 768px) {
            font-size: 0.7em;
        }
    }
}

.news_img {
    padding-left: 0px;
    padding-right: 0px;
}

.retail_page_client {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: none;
    padding-bottom: 50px !important;
}

.r_data {
    background-color: $beige;
    padding: 50px 0;

    .row_data {
        margin-bottom: 30px;
    }

    .row {
        justify-content: center;
    }

    .card {
        padding: 30px 30px 40px 30px;
        background-color: #F3F0EB;
        border-color: #E8E5E1;
        height: 100%;

        .first_div {
            display: flex;
            align-items: center;

            .img_div {
                background-color: #F6E4AA;
                width: 50px;
                height: 50px;
                border-radius: 10px;
                border: 1px solid $yellow-color;
                // display: flex;
                padding: 8px;

                @media screen and (max-width: 1200px) {
                    padding: 5px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .div_content {
                font-size: 1.5em;
                font-weight: bold;
                padding-left: 20px;
                margin-bottom: 0px;
            }

        }

        p {
            margin-top: 20px;

            span {
                font-weight: bolder;
                text-transform: uppercase;
            }
        }
    }
}


.main_edge {
    background-color: $beige;
    padding: 50px 0;

    &.retail_main {
        .transparent-button {
            margin-top: 30px;
            color: $black-color;
            font-size: 15px !important;
            // padding-top: 10px;
            // padding-left: 0px;
            // padding-right: 0px;
            border: 1px solid $black-color;
            border-radius: 30px;
            display: flex;
            padding: 5px 10px;

            span {
                margin-left: 10px;
                font-size: 20px;
            }
        }
    }

    .retail_main_tab {
        padding-top: 50px;

        #r_main_one {
            .r_main_pills {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .nav-item {
                    a {
                        text-align: center;
                        background: transparent;
                        color: black;
                        font-weight: bold;
                        border-bottom: 1px solid #8C8C8C;
                        border-radius: 0px;
                        width: 250px;
                        padding: 10px 20px;
                        font-size: 1.2em;

                        &.active {
                            border-width: 3px;
                            border-color: $orange-color;
                        }
                    }
                }

            }
        }
    }


}

.main-edge-hotel{
    background-color: $beige;
    padding: 50px 0;
    .hotel_card_tab {
        padding-top: 50px;
        #h_card_one {
            .r_main_pills {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .nav-item {
                    a {
                        text-align: center;
                        background: transparent;
                        color: black;
                        font-weight: bold;
                        border-bottom: 1px solid #8C8C8C;
                        border-radius: 0px;
                        width: 250px;
                        padding: 10px 20px;
                        font-size: 1.2em;

                        &.active {
                            border-width: 3px;
                            border-color: $orange-color;
                        }
                    }
                }

            }
        }
    }
    @media (min-width: 1024px) and (max-width: 1200px) {
        .hotel_card_tab {
            #h_card_one {
                .r_main_pills {
                    .nav-item {
                        a {
                            width: 200px; 
                            font-size: 1em;
                        }
                    }
                }
            }
        }
    }
}

.showcase-slider{
    opacity: 0.1;
    padding-top: 1em;
    padding-left: 1em;
    border-left: 3px solid var(--lighthouse-orange, #5c5c5c);
    transition: opacity 0.1s ease, border-left 0.3s ease, padding-left 0.3s ease;
}
.showcase-slider-item-active {
    .showcase-slider{
    opacity: 1;
    padding-top: 1em;
    padding-left: 1em;
    border-left: 3px solid var(--lighthouse-orange, #fd8237);
    transition: opacity 0.1s ease, border-left 0.3s ease, padding-left 0.3s ease;
    }
}
@media (max-width: 500px) {
    .showcase-slider{
        opacity: 0.3;
    }
    .showcase-slider-inactive {
        visibility: hidden;
    }
}

.h-customer-right {
    // opacity: 0;
    // scale: 0.5;
    // transition: opacity 0.1s ease, transform 0.3s ease;
}

.why_form,
.retail_form {
    border-top: none !important;
    padding-top: 50px;
    padding-bottom: 100px;
}

#smiles-at-sciative {
    video {
        height: 32rem;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
        padding: 5px 0;
    }

    .video_div {
        padding: 0px;
    }
}


.new_cards {
    padding: 50px 0;
    background-color: $beige;

    .new_container {
        padding: 20px;
        border: 1px solid #E8E5E1;
        border-radius: 10px;
        width: 100%;

        p {
            font-size: 1.1em;
            margin-bottom: 0px;
        }

        .new_div_2 {
            padding-left: 15px;

        }

        &.con_1 {
            margin-bottom: 70px;


        }

        @media screen and (max-width: 767px) {

            margin-bottom: 30px !important;
        }

        img {
            margin-bottom: 10px;
            width: 23px;
            height: 23px;
            object-fit: contain;
        }
    }

    .new_card_2 {
        @media screen and (min-width: 768px) {
            display: flex;
            align-items: center;
        }

        img {
            width: 100%;
            transform: scale(0.7);

            @media screen and (max-width: 1200px) and (min-width: 768px) {
                transform: scale(0.9);
            }

            @media screen and (max-width: 767px) {
                transform: scale(1);
                margin-bottom: 30px;
            }
        }
    }

    .new_card_4 {
        margin-top: 50px;

        @media screen and (max-width: 767px) {
            margin-top: 0px;
        }
    }
}

.err_page {
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

    img {
        width: 100%;
    }
}

.rr_1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rr_2 {
    @media screen and (min-width: 768px) {

        padding-bottom: 50px;
    }
}

// #profit_calulator{
//     background-color: $beige;
//     color: $black-color;
// }

.imgsection {
    img {
        width: 100%;
    }
}

.img_1 {
    margin-top: -2%;

    img {
        width: 100%;
    }
}

[data-aos="new-animation"] {
    opacity: 0;
    transition-property: transform, opacity;

    &.aos-animate {
        opacity: 1;

    }

    @media screen and (min-width: 768px) {
        transform: translateY(50px);

        &.aos-animate {
            transform: translateY(0);
        }
    }

    @media screen and (max-width: 767px) {
        transform: translateY(30px);

        &.aos-animate {
            transform: translateY(0);
        }
    }
}


.problems_div {
    padding: 75px 0 50px 0;

    .row {
        justify-content: center;
        align-items: center;
    }

    .card {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 50px;
        padding-bottom: 0px;
        position: relative;
        margin-bottom: 30px;

        .img_red {
            width: 100px;
            height: 100px;
            padding: 6%;
            background-color: #f00;
            border-radius: 50%;

            @media screen and (max-width: 1200px) {
                width: 70px;
                height: 70px;
            }

            &.green {
                background-color: #27C200;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        p {
            text-align: center;
            margin-top: 20px;

            @media screen and (min-width: 1200px) {

                height: 60px;
            }
        }



        &.card1 {
            .img_red {

                .correct {
                    display: none;
                }


            }

            .cr_aans {
                display: none;
            }

            &:hover {
                .img_red {

                    background-color: #27C200;
                }

                .correct,
                .cr_aans {
                    display: block;
                }

                .red_img,
                .wr_aans {
                    display: none;
                }

            }

            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                width: 1px;
                height: 100px;
                background-color: #919191;

            }

            &:nth-child(3n) {
                &::after {

                    background-color: transparent;
                }
            }

            // &:nth-last-child(1){
            //     &::after{

            //         background-color: transparent;
            //     }
            // }

        }

        &.card2 {
            margin-bottom: 15px;
            padding-bottom: 20px;

            &::after {
                content: "";
                position: absolute;
                left: auto;
                right: auto;
                bottom: 0;
                width: 100px;
                height: 1px;
                background-color: #919191;

            }
        }
    }

    .card1:nth-child(3n)::after {
        background-color: transparent;
    }
}

.mob-nav-logos {
    img {
        padding: 10px 0;
    }
}

.section_video_career {
    &.bus_video {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
}


.retail-static-page {
    .retail-static-page-logo {
        height: 2.5em;

        @media screen and (max-width: 768px) {
            height: 1.2em;
        }
    }

    .retail-static-page-header {
        margin-top: 0.2em;

        @media screen and (max-width: 768px) {
            margin-top: 1.2em;
        }
    }

    .retail-static-page-sub-header {
        @media screen and (max-width: 768px) {
            font-size: 1.2em;
        }
    }

    .retail-static-page-sub-heading {
        @media screen and (max-width: 768px) {
            font-size: 0.95em;
        }
    }

    .retail-static-page-profile {
        height: 100%;

        @media screen and (max-width: 768px) {
            height: 18em
        }
    }

    .retail-static-page-profile-text {
        @media screen and (max-width: 768px) {
            margin-top: 0.8em;
            font-size: 1.2em;
            text-align: justify;
        }
    }

    .retail-static-page-contact {
        &:hover {
            color: $yellow-color !important;
        }

        @media screen and (max-width: 768px) {
            text-align: center;
        }
    }

    .retail-static-page-contact-div {
        @media screen and (max-width: 768px) {
            justify-content: center !important;
            margin-bottom: 1.5em;
        }

        @media screen and (min-width: 767px) {
            margin-bottom: 0 !important;
        }
    }

    .retail-static-page-testimonial {
        @media screen and (max-width: 768px) {
            text-align: justify;
        }
    }

    .retail-static-page-link {
        text-decoration: underline !important;

        &:hover {
            text-decoration: underline !important;
        }
    }

    .sm-links {
        @media screen and (max-width: 768px) {
            font-size: 1.7em;
        }

        @media screen and (min-width: 769px) {
            font-size: 2em;
        }
    }

    .contact-btn {
        @media screen and (max-width: 768px) {
            width: 100% !important;
        }

        @media screen and (min-width: 767px) {
            width: 50%;
        }
    }

    .fa-star {
        color: $yellow-color;
        font-size: 0.7em;
    }

    .recog_img_div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .award_desc {
        text-align: center;
        font-size: 0.9em;
        margin-top: 1em;
    }

    .award_name {
        font-size: 0.7em;
        margin-top: 0;
    }

    .MuiGrid-container {
        @media screen and (min-width: 600px) and (max-width: 1200px) {
            justify-content: center;
        }
    }

    .award-section {
        margin-top: 4em;

        @media screen and (max-width: 768px) {
            margin-top: 0.3em !important;
        }
    }

    .retail-static-page-profile-col {
        @media screen and (min-width: 770px) and (max-width: 990px) {
            margin-bottom: 7em !important;
            height: 20em
        }
    }
}

.hotel_bg_cta {

    .ap_card {
        background-color: $black-color;
        color: $white-color;
        padding: 80px 70px 80px 80px;

        p {}
    }

}

.hotels_second {
    padding: 75px 0 50px 0;
    @media screen and (max-width: 767px) {
        padding-bottom: 0px;
    }
    .row{
        align-items: center;
    }
    img{
        width: 100%;
        @media screen and (max-width: 1023px) {
            margin-top: 20px;
        }
    }
  }
  .hotel_section_2{
    padding: 75px 0 50px 0;

    .card {
        background-color: #F3F0EB;
        padding: 25px;
    }

    .bigHead {
        margin-bottom: 50px;
        font-weight: 500;
    }

    img {
        width: 90px;
    }

    a {
        display: inline-flex;
        cursor: pointer;

        &:hover {
            color: $yellow-color !important;
        }
    }
}

.hotel-roadahead {
    .row {
        // background-color: #F5F5F5;
        padding: 15px;

    }

    h2 {
        font-size: 20px;
    }

}

.hotel-cta {
    .bigHead {
        margin-bottom: 2rem;
    }
}

.hotel_bg_cta {
    background-color: $beige;

    .card {
        background-size: cover;
        background-position: center;
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 30px;
        border-radius: 15px;
        border: none;

        @media screen and (max-width: 767px) {
            padding: 20px;
        }

        .card_text {
            color: $white-color;
            font-size: 1.7em;

            @media screen and (max-width: 768px) {
                text-align: center;
            }
        }

        .yellow-button {
            font-size: 1.4em;
            font-weight: 500;
        }

    }

    .ap_card {
        background-image: url(../img/hotels/bg_cta.jpg);

    }

    &.comp_cta {
        .ap_card {
            justify-content: center;
            height: 370px;
        }

        .card_text {
            font-size: 1.4em;

            @media screen and (max-width: 767px) {
                font-size: 1.2em;
                text-align: center;
            }
        }

        .yellow-button {
            @media screen and (max-width: 767px) {
                font-size: 1em;
            }
        }

        .comp_card {
            display: flex;
            justify-content: center;
            background-color: $yellow-color;
            padding: 15px 25px;
            align-items: center;
            border-radius: 15px;
            margin-bottom: 40px;

            @media screen and (max-width: 767px) {
                font-size: 0.5em;
                padding: 5px;
                width: 100% !important;
            }

            span {
                color: $black-color;
                margin-right: 20px;
                font-size: 1.2em;

                &.stext {
                    font-weight: 700;
                    margin-right: 20px;

                    @media screen and (max-width: 1200px) and (min-width: 768px) {
                        margin-right: 9px;
                    }
                }

                &.snum {
                    background-color: $white-color;
                    color: $black-color;
                    padding: 2px 15px;
                    margin-right: 20px;
                    font-weight: 700;
                }
            }
        }
    }

    &.hotel_bg_main {
        .ap_card {
            background-image: url(../img/hotels/hotel-bg-2.jpg);

        }
    }
}



.hotel_main_slider {
    .slick-dots li button::before {
        font-size: 12px;
    }
}


.hotel_main_slider {
    padding-top: 50px;
    margin-bottom: 70px;

    .bigHead {
        margin-bottom: 25px !important;
    }

    .slick-prev,
    .slick-next {
        top: -17%;

        @media screen and (max-width: 767px) {
            top: -12%;
        }

        @media screen and (max-width: 1023px) and (min-width: 768px) {
            top: -11%;
        }

        @media screen and (max-width: 1200px) and (min-width: 1024px) {
            height: 70px !important;
            top: -21%;
        }

        border-radius: 0px !important;

        &::before {
            color: $black-color;
        }

    }

    .slick-next {
        right: -22px;
        width: 100px;
        // border-top-right-radius: 50%;
        // border-bottom-right-radius: 50%;
        // background-image: #ffffff40;
        /* filter: blur(1px); */
        background-image: linear-gradient(to left, #fefbf5 40%, rgb(255 255 255 / 38%) 30%, rgb(254 251 245 / 53%) 30%);
        height: 60px;
        display: inline-flex ! IMPORTANT;
        align-items: center;
        justify-content: flex-end;

        &:hover {
            background-image: linear-gradient(to left, #fefbf5 40%, rgb(255 255 255 / 38%) 30%, rgb(254 251 245 / 53%) 30%);
        }

        @media screen and (max-width: 767px) {
            width: 50px;
            right: -6px;
            background-image: none;
            background-color: #fefbf5;

        }
    }

    .slick-prev {
        width: 100px;
        // border-top-right-radius: 50%;
        // border-bottom-right-radius: 50%;
        // background-image: #ffffff40;
        /* filter: blur(1px); */
        background-image: linear-gradient(to right, #fefbf5 40%, rgb(255 255 255 / 38%) 30%, rgb(254 251 245 / 53%) 30%);
        height: 60px;
        left: -22px;
        display: inline-flex ! IMPORTANT;
        align-items: center;

        &:hover {
            background-image: linear-gradient(to right, #fefbf5 40%, rgb(255 255 255 / 38%) 30%, rgb(254 251 245 / 53%) 30%);
        }

        @media screen and (max-width: 767px) {
            width: 50px;
            left: -7px;
            background-image: none;
            background-color: #fefbf5;
        }
    }

    .slider_card {
        @media screen and (min-width: 1200px) {
            height: 400px;
            object-fit: cover;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media screen and (min-width: 1200px) {
                // height: 300px;
                // object-fit: cover;
            }
        }
    }

    .row {
        align-items: center;

        img {
            border-radius: 5px;
        }

        .row_2 {
            padding-left: 8%;
            // padding-right: 10%;

            .slider_head {
                font-size: 1.2em;
                font-weight: 700;
            }

            .yellow-button {
                font-size: 12px;
              
            }

            @media screen and (max-width: 1023px) {
                margin-top: 20px;
            }
        }
    }

    .layer_content {
        border: 1px solid $yellow-color;
        padding: 1%;
        // padding-top: 2%;

        @media screen and (max-width: 1200px) {
            padding: 2%;
        }

    }

    .slick-dots {
        bottom: -65px;

        li {
            @media screen and (max-width: 1200px) {
                width: 10px;
            }
        }
    }

    .slick-dots-top {
        position: relative;
        bottom: 0px;
    }

    .slider_head2 {
        font-size: 0.8em;
        text-align: center;
        font-weight: 600;
        background-color: #F3F0EB;
        margin-left: 3%;
        margin-right: 3%;

        padding: 5px 20px;
        border-radius: 24px;
        height: 50px;
        align-items: center;
        display: flex;
        justify-content: center;
        border: 1px solid #F3F0EB;

        &:hover {
            cursor: pointer;
            border: 1px solid $yellow-color;
        }

        @media screen and (max-width: 1200px) and (min-width: 1024px) {
            height: 70px;
        }
    }

    .slick-slide.slick-current {
        .slider_head2 {
            background-color: $yellow-color;
        }
    }

    .layer_content_2 {
        margin-bottom: 2%;

        .slick-slide {
            // width: auto !important;
        }
    }
}

.hotel_testimonial_slider {
    padding: 75px 0 100px 0;

    .slider_card {
        img {
            width: 100%;
        }
    }

    .slider_div {
        align-items: center;
        padding: 30px;
        background-color: #F5F5F5;
        border: 1px solid #DDDDDD;
        margin: 20px;
        border-radius: 15px;

        .slider_card2 {
            img {
                width: 30px;
            }

            .slider-card2-1 {
                display: flex;
                align-items: center;

                p {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .slick-dots li {
        width: 10px;

        button::before {
            font-size: 12px;
        }
    } 
    .slick-arrow:before{
        color: $black-color !important;
    }
    .slick-arrow{
        &.slick-next{
            right: -10px;
        }
        &.slick-prev{
            left: -10px;
        }
    }
    // .slick-dots li button:before{
    //     content: "";
    // }
    // .slick-dots li {

    //     width: 5px;
    //     height: 3px;

    //     background: black;
    //     &.slick-active{
    //         background: gray;
    //     }
    // }
    .layer_content_3 {
        @media screen and (max-width: 767px) {
            .slider_div {
                padding-left: 5px;
                padding-right: 5px;
            }

            .slider_card2 {
                padding-top: 15px;
            }
        }
    }
}

    .smallBanner.hotel_faq{
        padding: 120px 0 50px 0;
        .banner_logo_sticky {
            width: 100%;
        }
        .yellow-button{
            color: #000;
        }
    } 

    .smallBanner.hotel_customer{
        padding: 120px 0 50px 0;
        .banner_logo_sticky {
            width: 100%;
        }
        .yellow-button{
            color: #000;
        }
    } 
    .mob-nav-logos.zetta-logos img {
        padding: 17px 0;
    }

    .smallBanner.hotel_faq #banner-logo{
        width: 170px;
    }

    .main_page_product{
        justify-content: center;
       
        .card_bus{
            background-image: linear-gradient(
                to top,
                rgb(0 0 0 / 100%),
                rgba(0, 0, 0, 10%)
              ), url(../../assets/img/home/bus-img.jpg);
        }
        .card_retail{
            background-image: linear-gradient(
                to top,
                rgb(0 0 0 / 100%),
                rgba(0, 0, 0, 10%)
              ), url(../../assets/img/home/retail-img.jpg);
        }
        .card_zetta{
            background-image: linear-gradient(
                to top,
                rgb(0 0 0 / 100%),
                rgba(0, 0, 0, 10%)
              ), url(../../assets/img/home/hotel-img.jpg);
        }
        .card{
                color: $white-color;
                justify-content: space-between;
                padding: 20px;
                height: 450px;
                border: 0px;
                background-position: center;
                border-radius: 25px;
                margin-bottom: 25px;
            p{
                margin-bottom: 0px;
             
            }
            .prod_name{

            }
            .ind_name{
                text-transform: uppercase;
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.5s ease, visibility 0.5s ease;
            }
            .ind_name_2{
                text-transform: uppercase;
                margin-bottom: 15px;

                opacity: 1;
                transition: opacity 0.5s ease, visibility 0.5s ease;
            }
            .text_normal{
                opacity: 1;
                transition: opacity 0.5s ease, visibility 0.5s ease;
            }
            img{
                width: 0px;
                opacity: 0;
                transition: opacity 0.5s ease;
               
            }
            &:hover{
                img{
                    width: 130px;
                    opacity: 1;
                }
                .ind_name{
                    visibility: visible;
                    opacity: 1;
               
                }
                .ind_name_2{
                    visibility: hidden;
                    opacity: 0;
    
                }
                .text_normal{
                    visibility: hidden;
                    opacity: 0;
                }
                &.card_zetta, &.card_bus{
                    img{
                        width: 180px;
                        opacity: 1;
                    }
                }
            }
            @media screen and (max-width: 1200px) {
                img{
                    width: 120px !important;
                    margin-top: 20px;
                    opacity: 1;
                }
                &:hover{
                    .text_normal{
                        visibility: visible;
                    }
                }
            }
        }
    }

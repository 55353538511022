.header-bar {
    background-color: #cdcdcd;
    height: 10px;
}

.form-bar {
    background-color: #c3e0ea;
    height: 50px;
}

.sub-header-bar {
    background-color: #ededed;
    height: 10px;
}

.btn-bar {
    background-color: #ffc601;
    height: 8px;
}

.template-box {
    border: 1px solid;
    border-color: rgb(210, 210, 210);
    border-radius: 20px;
    cursor: pointer;

    &:hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-color: black;
    }
}

.template-box.active {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-color: black;
}

.template-name {
    font-size: 0.9rem;
}

.info-icon {
    font-size: 1rem;
    cursor: pointer;
}

.form-label {
    font-size: 1rem;
}

.fields-table {
    font-size: 1rem;
}

.helper-text {
    color: gray;
    font-style: italic;
    font-size: 1rem;
}

.float-btn {
    position: fixed !important;
    bottom: 2rem;
    right: 5rem;
    background-color:#22bb33 !important;
    color: white !important;
}

.form-label.required::after {
    content: "*";
    color: #dc3545;
    margin-left: 2px;
}
.chatbotBtnContainer {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    gap: 0.8rem;
    z-index: 9999;
}

.msgContainer {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    color: #595858;
    cursor: pointer;
}

.chatBtn {
    padding: 0;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);

    img {
        width: 70px;
        height: 70px;
    }
}

.iFrameContainer {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 99999;

    iframe {
        width: 27rem;
        height: 32rem;
    }
}

.chatCloseIcon {
    position: absolute;
    top: -10px;
    left: -10px;
    background-color: #bb2124;
    color: white;
    border-radius: 50%;
    padding: 2.5px;
    cursor: pointer;

    &:hover {
        background-color: #941a1c;
    }
}

@media screen and (width <= 481px) {
    .chatbotBtnContainer {
        position: fixed;
        bottom: 4rem;
        right: 2rem;
        display: flex;
        gap: 0.8rem;
        z-index: 9999;
    }
    .iconMsgContainer {
        display: none;
    }

    .iFrameContainer {
        right: auto;

        iframe {
            width: 90vw;
        }
    }
}

@media screen and (width <= 440px) {
    .iFrameContainer iframe {
        height: 25rem;
    }
}
/* general */
.ratalks {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
    background-color: #FEFBF5;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

/* container */
.container {
    margin-inline: 5vw;
}

/* landing section */
.landingSection {
    background-image: url("../../img/ratalks_bg_overlay.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    padding-top: max(4rem, 7vw);
    padding-bottom: 2rem;
    margin-top: 6rem;
}

.landingSection2 {
    background-image: url("../../img/ra_newsletter_bg_overlay.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    padding-top: max(4rem, 7vw);
    padding-bottom: 2rem;
    margin-top: 6rem;
}

.landingSection .landingUpper {
    display: flex;
}

.landingSection .landingUpper .leftContent {
    width: 50%;
}

.landingSection .landingUpper .leftContent h1 {
    color: white;
    text-shadow: 1.5px 0 white;
    letter-spacing: 1px;
    font-weight: bold;
    margin-bottom: 1.8rem;
}

.landingSection .landingUpper .leftContent p {
    font-size: max(0.8rem, 1.05vw);
}

.landingSection .landingUpper .leftContent p:first-of-type {
    margin-bottom: 3vw;
}

.landingSection .landingUpper .rightImg {
    width: 50%;
    display: flex;
    justify-content: center;
}

.landingSection .landingUpper .rightImg img {
    width: max(7rem, 17vw);
    object-fit: contain;
}

.landingSection .landingLower {
    margin-top: max(2rem, 3vw);
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 4vw;
}

.landingSection .landingLower img {
    width: max(8rem, 10vw);
    object-fit: contain;
}

.landingSection .landingLower span {
    font-size: max(0.9rem, 1.5vw);
    letter-spacing: 2px;
    text-align: center;
    line-height: 1rem;
}

.landingSection .landingLower div {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.landingSection .landingLower .lowerLeft {
    justify-content: end;
}

/* filter section */
.filterSection {
    display: flex;
    justify-content: center;
    margin-top: max(1.3rem, 2.2vw);
    margin-bottom: 4vw;
}

.filterSection ul {
    display: inline-block;
    padding: 0;
}

.filterSection ul li {
    list-style-type: none;
    float: left;
    padding-inline: 3vw;
    border-bottom: 1px solid #D6DCE8;
    padding-bottom: 10px;
    font-size: max(0.9rem, 1.2vw);
    font-weight: 600;
    cursor: pointer;

    &:hover {
        border-bottom: 3px solid #f3c9ab;
    }
}

.filterSection ul .activeFilter {
    border-bottom: 3px solid #FF6B00 !important;
}

.textCenter {
    text-align: center;
    color: gray;
}

.cardContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.3rem;
}

.cardContainer .card {
    background-color: #F3F0EB;
    border-radius: 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    height: 100%;

    &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 6px 10px 0px;
        transform: translateY(-4px);
    }
}

.newsletter-issue-img {
    height: 5em !important;
    width: auto;
}


.cardContainer .card .imgContainer img {
    width: 100%;
    border-radius: 16px 16px 0 0;
}

.cardContainer .card .contentContainer {
    padding: max(1rem, 1.5vw);
}

.cardContainer .card .contentContainer h4 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    color: black;
    font-size: max(1rem, 1.2vw);
    font-weight: 600;
    margin-bottom: 3rem;
}

.cardContainer .card .contentContainer span {
    color: #9A9A9A;
    font-size: max(1.05vw, 0.8rem);
}

/* guest speaker button */
.GuestBtnContainer {
    display: flex;
    margin-block: 2.5rem;
}

.GuestBtn {
    margin-inline: auto;
    background-color: #FFC50B;
    color: black;
    border: none;
    padding-inline: 1.5rem;
    font-size: max(1rem, 1.1vw);
    font-weight: 650;
    border-radius: 35px;
    margin-top: 1rem;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.3s ease-in-out;

    &:hover {
        background-color: black;
        color: #FFC50B;
        border: 1px solid #FFC50B;
    }
}

.formContainer {
    margin-inline: 7vw;
    display: flex;
    background-color: #F3F0EB;
    border-radius: 16px;
    padding-block: 2vw;
}

.formContainer .leftContent {
    width: 50%;
    padding: 2vw 8.5vw 2vw 2.6vw;
}

.formContainer .leftContent h2 {
    font-weight: 700;
    font-size: max(1.4rem, 2.4vw);
}

.formContainer .formRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5vw;
    padding-inline: 2vw;
}

.formContainer .formRight input {
    border: none;
    padding-block: 1.3vw;
    padding-inline-start: max(1rem, 1vw);
    border-radius: 16px;
}

.formContainer .formRight::placeholder {
    color: #9A9A9A;
}

.formContainer .formRight button {
    background-color: #FFC50B;
    color: black;
    border: none;
    padding-inline: 1.5rem;
    font-size: max(1rem, 1.1vw);
    font-weight: 650;
    border-radius: 35px;
    margin-top: 1rem;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.3s ease-in-out;

    &:hover {
        background-color: black;
        color: #FFC50B;
        border: 1px solid #FFC50B;
    }
}

.spacer {
    height: 8vw;
}


/* media queries */

@media (width <= 820px) {
    .landingSection .landingUpper .leftContent {
        width: 65%;
    }
}


@media (width <= 768px) {
    .cardContainer {
        grid-template-columns: repeat(2, 1fr);
    }

    .formContainer {
        flex-direction: column;
    }

    .formContainer .leftContent {
        width: 100%;
    }

    .formContainer .formRight {
        width: 100%;
    }
}


@media (width <= 582px) {
    .landingSection .landingUpper {
        flex-direction: column-reverse;
        gap: 1rem;
    }

    .landingSection .landingUpper .leftContent {
        width: 100%;
    }

    .landingSection .landingUpper .rightImg {
        width: 100%;
    }

    .landingSection .landingLower {
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }

    .landingSection .landingLower .lowerLeft, .landingSection .landingLower .lowerRight {
        justify-content: center;
    }
}
img[alt="sciative"] {
    height: 3em;
    width: auto;
  }
  
  /* Add media queries for smaller viewports */
  @media (width <= 480px) {
    img[alt="sciative"] {
      height: 2em;
    }
  }
  
  @media (width <= 360px) {
    img[alt="sciative"] {
      height: 1.5em;
    }
  }
  
  
@media (width <= 480px) {
    .cardContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (width <= 370px) {
    .filterSection ul li {
      font-size: 0.8rem;
      padding: 0 0.5rem;
    }
  }
.row{
    max-width: 100vw !important;
}

body {
    background-color: #FEFBF5 !important;
}

#blog-section{
    width: 100%;
}
.main-blogsection-blog-card {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
}
.main-blogsection-h3 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 1.25rem;
    line-height: 1.3;
}
#additional-content a{
    color: blue;
}

#additional-content p{
    font-weight: 300;
}

#additional-content strong{
    font-weight: 700;
}

#additional-content h3{
    margin-bottom: 1em;
    margin-top: 1.5em;
}
#additional-content h4{
    margin-bottom: 1em;
    margin-top: 1.5em;

}
#additional-content h5{
    margin-bottom: 1em;
    margin-top: 1.5em;

}

.blog-img{
	height: 15em;
	width: 18em;
	border-radius: 8px;
}
.blog-img-1{
	height: 18em;
	width: auto;
	border-radius: 8px;
}
.blog-img-2{
	height: 12em;
	border-radius: 8px;
}
.blog-img-3{
	height: 140px;
	width: 140px;
	border-radius: 8px;
}
.blog-img-text{
	height: 250px;
	width: 100%;
	border-radius: 8px;
}

.blog-title-section {
    position: relative;
    height: 65vh !important;
    overflow: hidden;
    /* to ensure the overlay doesn't spill out */
    padding: 0;
}

.blog-title-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    /* adjust the alpha value to control transparency */
}



.blog-title {
    font-size: 2.2em;
    font-weight: 800;
    text-align: center;
    z-index: 1;
}

.category-tile {
    border-radius: 200px;
    background-color: #fff;
    font-size: 0.7em;
    z-index: 1;
}

.category-tile a {
    color: #000;
}
.category-tile a:hover {
    text-decoration: underline !important;
}

.date-author-section {
    z-index: 1;
}

.author-name {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

.author-name:hover {
    text-decoration: underline;
}

.rec-blog-card {
    border: 1.33px solid #bbc;
    border-radius: 12px;
    background-color: #f1f1f1;
}

.rec-blog-thumbnail {
    border-radius: 12px;
}

.rec-blog-title {
    cursor: pointer;
}

.rec-blog-title:hover {
    text-decoration: underline;
}

.rec-blog-category {
    border-radius: 4px;
    background-color: #FF7A00;
    color: #FFF;
    font-size: 0.55em;
}

.rec-blog-author-img {
    border-radius: 200px;
    height: 40px;
    width: 40px;
}

.line {
    display: block;
    content: '' !important;
    height: 1.8px !important;
    width: 100px !important;
    background-color: #000 !important;
    color: #000 !important;
}


.progress-section{
    height: 60px;
    background-color: #fff;
    z-index: 100;
    width: 100%;
    top: 0px;
}

.sticky-section{
    position: -webkit-sticky; /* Safari */
    position: sticky !important;
    top: 0
    
}
@media (max-width:767px){
    .sticky-section .bigHead {
        font-size: 15px;
        margin-left: 15px !important;
        margin-right: 5px !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-left: 5px;
      }
}

/* Table of contents */
.toc-nav {
    width: 220px;
    min-width: 220px;
    padding: 16px;
    top: 70px;
    align-self: flex-start;
    max-height: calc(100vh - 70px);
    overflow: auto;
    background-color: #fff;
}

.toc-nav ul {
    list-style-type: none;
    padding-left: 0.5rem;
}
.toc-nav ul li {
    margin-bottom: 15px;
}
.toc-nav a {
    color: #000 !important;
}
.toc-nav a:hover {
    font-weight: bold;
}

.bold{
    font-weight: 700;
}

.head3{
    margin-left: 10px;
  }
  .head4{
    margin-left: 20px;
  }

  iframe{
    height: 21em;
    width: 40em;
    border-radius: 18px;
  }
  .yt-shorts{
    height: 30em !important;
    width: 15em !important;
  }
  .linked-in-iframe{
    height: 900px !important;
    width: 100% !important;
    border: 1.5px solid #ededed;
  }

  .hero-card {
    /* transition: 0.3s ease-in-out;

    &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 5px 0px;
        transform: translateY(-2px);
    } */
  }

  .blog-card {
    /* border-radius: 16px;
    transition: 0.3s ease-in-out;

    &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 5px 0px;
        transform: translateY(-2px);
    } */

  }

  .read-more-card {
    background-image: url('../img/blue_rectangle.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 10em;
  }

  .read-more-card span:hover {
    text-decoration: underline;
  }

  .read-more-card .icon-img {
    width: max(3rem, 3vw);
  }
  
  .blog-card-img{
    object-fit: cover;
    height: 10em;
    content: '';
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
  }

  .blog-card a:hover {
    text-decoration: underline !important;
    text-decoration-color: black !important;
}

.hero-card a:hover {
    text-decoration: underline !important;
    text-decoration-color: white !important;
  }

  .main-blog{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 18em !important;
  }
  .main-blog-gradient{
    height: 130px;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 85%);
  }

.input-box {
    background-color: black;
    border: 1px solid grey;
    border-radius: 25px;
    width: 80%;
    padding-left: 1rem;
}

.subscribe-btn {
    font-weight: bold;
    background-color: #FFC601;
    border-radius: 25px;
    width: 9rem;
    color: #000;
    border: 1px solid transparent;
    transition: 0.3s ease-in-out;
    font-size: 0.9em;
    &:hover {
        background-color: #000;
        color: #FFC601;
        border: 1px solid #FFC601;
    }
}

.sm-buttons button{
    border-radius: 200px;
}
.sm-buttons svg{
    border-radius: 200px;
}
.twitter-btn{
    border-radius: 200px;
}

.filter-section{
    font-size: 0.6em;
}
.filter-section button{
    background-color: transparent;
    border-radius: 8px;
    color: #111;
    font-weight: bold;
    border: 1.2px solid #888;
    padding: 0.5%;
    margin-bottom: 0.5%;
}
.filter-section .selected{
    background-color: #FFC601;
    border: none;
}

.picker-button{
    /* width: 100%; */
    width: 3em;
    height:10px;
    border-radius: 4px;
    padding: 0;
    cursor: pointer;
    border: 1px solid black;
    position:relative;
    border: 1px solid grey;
}
.color-picker-img{
    height: 3em;
    width: 3em;
    cursor: pointer;
}
.table-alignement{
    height: 3em;
    width: 3em;
    cursor: pointer;
}
.table-bullet , .table-ordered{
    padding-left: 1rem;
}
.table-bullet li,.table-ordered li{
    /* font-size: 0.7rem !important; */
    white-space: normal; 
}
.table-bullet ul,.table-ordered ul{
    /* font-size: 0.7rem !important; */
    white-space: normal; 
}
.table-ordered ol,.table-bullet ol{
    padding-left: 1rem;
}
.table-container-blog-admin{
    width: 89% !important;
}
.blog-img-horizontal{
    width: 100%;
    height: 40em;
}


.visible-content p{
    font-size: 1.4em;
    overflow-wrap: break-word;
  }

.read-more{
    padding: 0.5em;
    border-radius: 10px;
    width: 8em;
    font-size: 0.85em;
    background-color: #FFC601;
    border: 1px solid #FFC601;
    color: black;
}
.read-more:hover{
    background-color: white;
    color: black;
    border: 1px solid black;
}
  
.icon-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    margin: 0 1em;
    cursor: pointer;
}

.social-share{
    margin-bottom: 0;
}
.social-share svg {
    border-radius: 50px;
}


@media (max-width:320px)  { 
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
    #main_navbar{
        top: 0 !important;
    }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px){
    #main_navbar{
        top: 0;
    }
    .blog-img-horizontal{
        width: 100%;
        /* height: 150px !important; */
        height: auto;
    }
    .blog-img-2{
        height: 100px;
        width: 100px
    }
    .blog-img-3{
        height: 80px;
        width: 80px
    }
    .blog-title{
        font-size: 1.8em;
    }
    .table-container-blog-admin{
        overflow: scroll;
        width: 100% !important;
    }
    .table-container-blog-admin > :first-child {
        overflow: scroll !important;
    }
    .dynamic-width  .table-td ,.dynamic-width  .table-th{
        width:60% !important;
        overflow: scroll !important;
    }
    .fixed-width .table-th {
        font-size: 1rem !important;
    }
    .fixed-width  .table-td{
        font-size: 0.8rem !important;
    }

    .table{
        overflow: auto !important;
    }
    .table,.table-container-blog-admin {
        scrollbar-width: thin !important; 
        scrollbar-color: auto !important; 
    

        &::-webkit-scrollbar {
            width: 10px !important; 
            height: 10px !important; 
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey !important;
            border-radius: 5px !important; 
        }
    
        &::-webkit-scrollbar-track {
            background-color: #f1f1f1 !important;
        }
    }
    .table::-webkit-scrollbar,.table-container-blog-admin::-webkit-scrollbar {
        display: block !important; 
    }
    

}


@media (max-width:700px)  {     
    .blog-container {
        padding: 0;
        margin: 0;
      }
    
      
      .blog-content {
        padding: 1rem;
      }
    
      
      
      .title-container {
        display: flex;
        justify-content: flex-start;
        width: 100%;
      }
      
      .blog-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        color: #1a1a1a;
        text-align: left;
        margin: 0;
        padding: 0;
      }
      
      
      .author-name {
        font-size: 14px;
        color: #1a1a1a;
        margin-bottom: 4px;
      }
      
      .author-container {
        margin-right: 4px; /* Even smaller gap on mobile */
      }
      .date {
        font-size: 14px;
        color: #1a1a1a;
        
      }
      
      .separator {
        margin: 0 4px; /* Even smaller gap on mobile */
      }
      .meta-details {
        font-size: 14px;
        color: #666;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      
      .categories-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-top: 0.25rem;
      }
    
      .category-link {
        margin: 0; /* Remove horizontal margins */
      }

      .blog-meta {
        margin-top: 0.5rem;
      }
    
      .blog-meta .d-flex {
        align-items: flex-start !important;
      }
    
      .blog-meta .d-flex .author-name {
        font-size: 14px;
        margin-bottom: 4px;
      }
    
      .blog-meta .d-flex .text-grey {
        font-size: 14px;
      }
      .meta-details {
        font-size: 14px;
        color: #666;
        width: 100%;
      }
    
      .category-tile {
        display: inline-block;
        padding: 2px 8px;
        background-color: #f5f5f5;
        border-radius: 200px;
        color: #000;
        font-size: 14px;
        transition: background-color 0.2s ease;
      }
      
      .category-tile:hover {
        background-color: #e5e5e5;
      }
      
      .blog-subtitle {
        font-size: 18px;
        font-weight: 500;
        color: #333;
      }
      
      .blog-description {
        font-size: 16px;
        line-height: 1.6;
        color: #666;
      }
      
      .blog-image-container {
        width: 100%;
        margin: 0 0 1rem 0;
      }
      
      .blog-image {
        width: 100%;
        height: 300px;
        object-fit: cover;
      }
      
      /* Desktop styles */
      @media (min-width: 768px) {
        #blog-section {
          min-height: 100vh;
          padding: 2rem;
        }
      
        .blog-content {
          max-width: 700px;
          padding: 2rem;
        }
      
        .blog-image {
          border-radius: 0.5rem;
          height: 400px;
        }
      }
      
      /* Large desktop styles */
      @media (min-width: 1200px) {
        .blog-content {
          max-width: 800px;
          padding: 3rem;
        }
      }
      
      
}

@media (min-width:600px)  and (max-device-width: 1000px) { 
    .toc-nav .subscribe-btn{
        width: 100%;
    }
    .toc-nav .input-box{
        width: 100%;
    }
    
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
}

@media (max-width:800px){
    .blog-img-1{
        height: auto;
        width: 100%;
        border-radius: 8px;
    }
}
@media (min-width:801px)  {

}
@media (min-width:1025px) { 

    /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }
.inline-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.inline-list li {
  margin-right: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  
}


.letter-sticky {
  background-color: #fefaf4;
  transition: position 0.2s linear;
}

.letter-sticky.fixed {
  position: fixed;
  top: 115px; /* Fixed to the top */
  z-index: 2;
}

.glos-input-box {
  border-radius: 200px;
  border: 1.2px solid #bdbdbd;
  background-color: transparent;
}
.glos-input-box::placeholder {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .glos-input-box::placeholder {
    /* font-size: 0.8rem; */
  }
}



.glos-search-btn {
  font-weight: bold;
  background-color: #ffc601;
  border-radius: 25px;
  /* width: 9rem; */
  color: #000;
  border: 1px solid transparent;
  transition: 0.3s ease-in-out;
  /* font-size: 0.9em; */
  padding: 0.5em 0.5em;
  &:hover {
    background-color: #000;
    color: #ffc601;
    border: 1px solid #ffc601;
  }
}
.glos-search-btn {
  font-weight: bold;
  background-color: #ffc601;
  color: #000;
  border-radius: 25px;
  border: 1px solid transparent;
  transition: 0.3s ease-in-out;
  padding: 0.5em 0.5em;

  &:hover {
    background-color: #000;
    color: #ffc601;
    border: 1px solid #ffc601;
  }

  @media (min-width: 768px) {
    width: 9rem;
  }
}

@media (768px <= width <= 820px ){
  .subscribe-btn {
      font-size: 0.8em;
  }
}

.glossary-ele:hover h3 {
  color: #ff7f50;
}

.glossary-banner {
  background-image: linear-gradient(
      to right,
      rgb(0 0 0 / 88%),
      rgba(0, 0, 0, 47%)
    ),
    url("../img/glossary_1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  padding-top: max(13rem, 7vw);
  padding-bottom: 2rem;
  margin-top: 2rem;
}

.selected-alpha {
  color: #ff7f50 !important;
  font-weight: 600;
  text-decoration: underline;
}

.disabled {
  color: "grey";
  pointer-events: none;
  cursor: "not-allowed";
  opacity: 0.2;
  text-decoration: none;
}

.enabled {
  color: #333;
  border: none;
  transition: color 0.3s, font-weight 0.3s;
  font-weight: 500;

  &:hover {
    color: #000;
    font-weight: 600;
  }
}
